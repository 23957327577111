import React, {useState, useEffect, useRef} from 'react'
import { useSelector } from "react-redux"
import {useLocation, useParams, Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import {Pagination, Input, Row, Button, Typography, Col, Tabs,  Divider, Spin, Flex } from 'antd'
// import {connect} from 'react-redux'
import {debounce, openHideLink} from "../../../Utils/utils"


import Images from '../../../Images'
import {colors} from '../../../colors'
import './footer.less'

const { Text, Title } = Typography;

const fakeImagesUrls = [
  {
    foto: 'https://cdn.prod.website-files.com/65d8ee5f025f02594c614c17/66546e32d2265f63409812e5_preview.webp',
    title: 'Changing the Legal Landscape with AI Integration',
    link: 'https://dataforest.ai/blog/changing-the-legal-landscape-with-ai-integration',
    date: 'May 27, 2024',
    time_reading: 21,
  },
  {
    foto: 'https://cdn.prod.website-files.com/65d8ee5f025f02594c614c17/665471978e18ef6d301b27ce_preview.webp',
    title: 'AI in Automotive: Transforming the Automobile Industry',
    link: 'https://dataforest.ai/blog/ai-in-automotive-transforming-the-automobile-industry',
    date: 'May 27, 2024',
    time_reading: 23,
  },
  {
    foto: 'https://cdn.prod.website-files.com/65d8ee5f025f02594c614c17/6654b18c18d58342809a2652_preview-p-800.webp',
    title: 'The Transformative Impact of AI in Energy Efficiency',
    link: 'https://dataforest.ai/blog/the-transformative-impact-of-ai-in-energy-efficiency',
    date: 'May 27, 2024',
    time_reading: 19,
  },
  // {foto: Images.example1, title: 'The Cumulative Impact of Having the Wrong Name'},
  // {foto: Images.example2, title: 'How Today`s AI Art Debate Will Shape the Creative Landscape'},
  // {foto: Images.example3, title: 'Agile Projects Have Become Waterfall Projects With Sprints'},
]

export const Footer = ({fetching, ...props}) => {


  return (
    <div className='footer'>

      <Row style={{alignItems: 'end'}} justify={'space-between'}>
        <Title className='main-title more'>More publications</Title>
          <Button
            type="link" className='button-link-underline'
            href='https://dataforest.ai/blog'
          >
            All publications
          </Button>
        </Row>

        <Row gutter={[33, 33]} style={{marginTop: 40}}>
        {fakeImagesUrls?.map((el, i) => (
          <Col span={8} key={i}>
            <div className='publication-wrapper' onClick={()=> openHideLink(el?.link)}>
              <img src={el?.foto} alt=""  width={'100%'}/>
              <Row align={'middle'} style={{marginTop: 30}}>
                <Text style={{fontWeight: 300, fontSize: 16, marginRight: 17}}>{el?.date}</Text>
                <img src={Images.circle} alt="" />
                <Text style={{fontWeight: 300, fontSize: 16, marginLeft: 17}}>{el?.time_reading} min reading</Text>
              </Row>
              <Title style={{fontSize: 30, fontWeight: 400}}>{el?.title}</Title>
            </div>
          </Col>
        ))}
      </Row>

      <Divider style={{marginTop: 127, marginBottom: 56, width: '130%', marginLeft: '-15%' }} />
     <Row gutter={[33, 33]}>
        <Col span={5} >
          <img src={Images.dataforest} /><br/>
          <Text className='footer-text-item' style={{marginTop: 26}}>© 2024 DataForest <br/> All Rights Reserved</Text>
          <Text className='footer-text-item' style={{marginTop: 24}}>+1 646 905 0356</Text>
          <Text className='footer-text-item'>info@dataforest.ai</Text>
          <Flex className='footer-text-item'>
            <a href='https://www.facebook.com/dataforest' target='_blank' style={{marginRight: 12}}>
              <img src={Images.fb2} />
            </a>
            <a href='https://www.linkedin.com/uas/login?session_redirect=%2Fcompany%2F11813841' target='_blank'>
              <img src={Images.linked} />
            </a>
          </Flex>

        </Col>
        <Col span={5}>
          <Title className='footer-title-item'>Services</Title>
          <Text className='footer-text-item' style={{marginTop: 26}} onClick={()=> openHideLink('https://dataforest.ai/services/data-scraping')}>Web scraping services</Text>
          <Text className='footer-text-item' onClick={()=> openHideLink('https://dataforest.ai/services/data-science')}>Data science</Text>
          <Text className='footer-text-item' onClick={()=> openHideLink('https://dataforest.ai/services/data-integration')}>System integrations</Text>
          <Text className='footer-text-item' onClick={()=> openHideLink('https://dataforest.ai/services/web-applications')}>Web applications</Text>
          <Text className='footer-text-item' onClick={()=> openHideLink('https://dataforest.ai/services/devops-as-a-service')}>DevOps</Text>
        </Col>
        <Col span={5}>
          <Title className='footer-title-item'>Solutions</Title>
          <Text className='footer-text-item' style={{marginTop: 26}} onClick={()=> openHideLink('https://dataforest.ai/cases')}>Data insights & Forecasting</Text>
          <Text className='footer-text-item' onClick={()=> openHideLink('https://dataforest.ai/cases')}>Business process automation</Text>
          <Text className='footer-text-item' onClick={()=> openHideLink('https://dataforest.ai/cases')}>Marketing automation</Text>
          <Text className='footer-text-item' onClick={()=> openHideLink('https://dataforest.ai/cases')}>Sales automation</Text>
          <Text className='footer-text-item' onClick={()=> openHideLink('https://dataforest.ai/cases')}>Supply chain automation</Text>
          <Text className='footer-text-item' onClick={()=> openHideLink('https://dataforest.ai/cases')}>Security automation</Text>
        </Col>
        <Col span={4}>
          <Title className='footer-title-item'>Industries</Title>
          <Text className='footer-text-item' style={{marginTop: 26}} onClick={()=> openHideLink('https://dataforest.ai/industry/ecommerce')}>Ecommerce</Text>
          <Text className='footer-text-item' onClick={()=> openHideLink('https://dataforest.ai/industry/ecommerce')}>Retail</Text>
          <Text className='footer-text-item' onClick={()=> openHideLink('https://dataforest.ai/cases')}>Finance</Text>
          <Text className='footer-text-item' onClick={()=> openHideLink('https://dataforest.ai/industry/travel-tech')}>Tech</Text>
          <Text className='footer-text-item' onClick={()=> openHideLink('https://dataforest.ai/cases')}>Manufacturing</Text>
          <Text className='footer-text-item' onClick={()=> openHideLink('https://dataforest.ai/cases')}>Real estate</Text>
          <Text className='footer-text-item' onClick={()=> openHideLink('https://dataforest.ai/cases')}>Farma</Text>
          <Text className='footer-text-item' onClick={()=> openHideLink('https://dataforest.ai/cases')}>Insurance</Text>
        </Col>
        <Col span={5}>
          <Title className='footer-title-item'>Company</Title>
          <Text className='footer-text-item' style={{marginTop: 26}} onClick={()=> openHideLink('https://dataforest.ai/about-us')}>About Us</Text>
          <Text className='footer-text-item' onClick={()=> openHideLink('https://dataforest.ai/cases')}>Success stories</Text>
          <Text className='footer-text-item' onClick={()=> openHideLink('https://dataforest.hurma.work/public-vacancies')}>Join the team</Text>
          <Text className='footer-text-item' onClick={()=> openHideLink('https://dataforest.ai/blog')}>Blog</Text>
          <Text className='footer-text-item' onClick={()=> openHideLink('https://dataforest.ai/contact-us')}>Contacts</Text>

        </Col>
      </Row>

      <Divider style={{ marginTop: 53, marginBottom: 41, width: '100%'}} />
      <Row gutter={0}>
        <Col span={5} style={{display: 'flex', alignItems: 'center'}}>
          <div className='footer-link' onClick={()=> openHideLink('https://clutch.co/profile/dataforest')}>
            <img src={Images.clutch} alt=""/>
            <Text style={{fontSize: 24, color: colors.mainDark, paddingLeft: 10}}>5.0</Text>
            <img src={Images.starblack} alt=""/>
          </div>
        </Col>
        <Col span={5} style={{display: 'flex', alignItems: 'center'}}>
          <div className='footer-link' onClick={()=> openHideLink('https://www.upwork.com/ag/dataforest/')}>
            <img src={Images.upwork} alt="" style={{position: 'absolute', top: 2}}/>
            <Text style={{fontSize: 24, color: colors.mainDark, paddingLeft: 118}}>5.0</Text>
            <img src={Images.starblack} alt=""/>
          </div>
        </Col>
        <Col span={4} offset={10} style={{display: 'flex', justifyContent: 'end'}}>
          <div className='footer-link' onClick={()=> openHideLink('https://dataforest.ai/privacy-policy')}>
            <Text style={{fontSize: 16, color: colors.mainDark, }}>Privacy Policy</Text>
          </div>
        </Col>
      </Row>


    </div>
  )
}


export default Footer