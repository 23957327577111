import { takeLatest, takeEvery, put, call } from 'redux-saga/effects';

import * as services from './services';
import Creators, { CatalogTypes as constants } from './reducer';
import ErrorsActions from '../../Redux/ErrorsRedux'

const actions = {
  getCatalogDataActions: {
    request: Creators.getCatalogDataRequest,
    success: Creators.getCatalogDataSuccess,
  },
};

const eventsOptions = {
  [constants.GET_CATALOG_DATA_REQUEST]: {
    api: services.getCatalogData,
    actions: actions.getCatalogDataActions,
  },
};

function* apiGenerator(action) {
  const provider = eventsOptions[action.type];
  try {
    const params = action.payload;
    const response = yield call(provider.api, params);
    if (response.ok && response.data) {
      yield put(provider.actions.success(response.data));
    } else {
      yield put(Creators.catalogPageFetchingReset());
      yield put(ErrorsActions.errorSave(response.data))
    }
  } catch (errors) {
    yield put(Creators.catalogPageFetchingReset());
    yield put(ErrorsActions.errorSave(response.data))
  }
}

export default function* apiSaga() {
  yield takeLatest(constants.GET_CATALOG_DATA_REQUEST, apiGenerator);
}
