import React, {useEffect, useState} from 'react'
import {Form, Input, Button, Row, Col, Typography, DatePicker, Select} from 'antd'
import Icon from '../../Icon'
import Images from '../../Images'
import './searchform.less'
import {useLocation, useHistory} from 'react-router-dom'

const {Text} = Typography

const optionsRating = [
  {
    value: '1',
    label: '1 star',
  },
  {
    value: '2',
    label: '2 stars',
  },
  {
    value: '3',
    label: '3 stars',
  },
  {
    value: '4',
    label: '4 stars',
  },
  {
    value: '5',
    label: '5 stars',
  },
]

function SearchForm({data, options, onSubmit, selectFilter, disabled, apiKeySelected, optionsSubCategories, optionsCategories,
  firsLoading, filterBy, setFilterBy, handleSearch, searchQuery, setSearchQuery,  ...props}) {

  const history = useHistory()
  const location = useLocation()
  const { pathname } = location;

  const [form] = Form.useForm()
  const category = Form.useWatch('category', form);
  const subcategory = Form.useWatch('subcategory', form);
  const segment = Form.useWatch('segment', form);
  const rating = Form.useWatch('rating', form);
  // const pricing = Form.useWatch('pricing', form);
  // const search = Form.useWatch('search', form);
  // const segment2 = Form.useWatch('segment2', form);

  const [filtersNumber, setFiltersNumber] = useState()
  const [firstLoading, setFirstLoading] = useState(true)

  useEffect(()=> {
    setFirstLoading(false)
  }, [])

  const pathnameArr = pathname.split('/').filter(Boolean);
  const categoryInUrl = pathnameArr[1];
  const subCategoryInUrl = pathnameArr[2];

  useEffect(()=> {
    if (!firstLoading && (categoryInUrl && subCategoryInUrl) && form.getFieldValue('category') !==  categoryInUrl) {
      form.setFieldValue('subcategory', undefined);
    }
  }, [category])

  useEffect(()=>{
    if (!firstLoading) {
      if (segment === 'none') {
        form.setFieldValue('segment', undefined);
      } else if (rating === 'none') {
        form.setFieldValue('rating', undefined);
      } else if (category === 'none') {
        form.setFieldValue('category', undefined);
        form.setFieldValue('subcategory', undefined);
      } else if (subcategory === 'none') {
        form.setFieldValue('subcategory', undefined);
      } 
      // else {
        // let values = form.getFieldsValue();
        // onFinish(values);
      // }
      let url = `/catalog`;
      if ((category && category !== 'undefined') || (subcategory && subcategory !== 'undefined')) {
        if (category && category !== 'undefined') {
          url += `/${category}`;
          if (subcategory && subcategory !== 'undefined') {
            url += `/${subcategory}`;
          }
        }
      }
      history.push(url);

      let values = form.getFieldsValue();
      onFinish(values);
    }
  },[subcategory, category, segment, rating])

  useEffect(() => {
    if (!categoryInUrl && !subCategoryInUrl) {
      form.setFieldsValue({category: undefined, subcategory: undefined})
      return;
    }
    if (!categoryInUrl || !subCategoryInUrl) {
      if (!categoryInUrl) {
        form.setFieldValue('category', undefined)
        return;
      }
      if (!subCategoryInUrl) {
        form.setFieldValue('subcategory', undefined)
        return;
      }
    }
  }, [pathname]);

  // useEffect(() => {
  //   if (filterBy) {
  //     const { category, subcategory } = filterBy;
  //     let url = `/catalog`;

  //     if (category) {
  //       url += `/${category}`;
  //       if (subcategory) {
  //         url += `/${subcategory}`;
  //       }
  //     }

  //     history.push(url);
  //   }
  // }, [filterBy.category, filterBy.subcategory]);

  function transformData (values) {
    let data = {}
    for (let key in values) {
      if (values[key]) {
          data[key] = values[key]
        }
      }
    return data
  }

  const onFinish = values => {
    let data = transformData(values)
    if (data.search) delete data.search
    setFilterBy(data);
    setFiltersNumber(Object.values(values)?.filter(el=> el)?.length)
    // onSubmit(data)
  }

  return (
    <Form onFinish={onFinish}
          validateTrigger="onSubmit"
          className="search-form"
          layout="vertical"
          form={form}
          initialValues={{
            category: decodeURIComponent(categoryInUrl),
            subcategory: decodeURIComponent(subCategoryInUrl),
          }}
    >
      <Row gutter={[0, 6]} className='select-group-wrapper'>
        <Col xs={12} sm={12} md={6} lg={5} xl={7}>
          <Form.Item
            name="category"
            //  label="Category"
            >
              <Select
                placeholder='Choose category'
                options={optionsCategories}
                className='select-ais'
                suffixIcon={<Icon type='arrow_down_df_select' role="icon" />}
              />
          </Form.Item>
        </Col>

        <Col xs={12} sm={12} md={6} lg={5} xl={7}>
          <Form.Item
            name="subcategory"
            // label="Subcategory"
          >
            <Select
              placeholder='Choose subcategory'
              options={optionsSubCategories}
              className='select-subcategory'
              suffixIcon={<Icon type='arrow_down_df_select' role="icon" />}
              disabled={!category}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={14} xl={10}>
          <Form.Item
            name="search"
            // label="Period"
          >
            <Input
              placeholder='Search by company name'
              className='input-search'
              suffix={<img src={Images.search} alt='' />}
              onChange={(e)=> {
                handleSearch(e.target.value)
                form.setFieldValue('search', e.target.value)
              }}
            />
          </Form.Item>
        </Col>

      </Row>
      <Row gutter={[19, 19]} style={{marginTop: 59}}>
        <Col lg={4} xl={4}>
          <Form.Item name="segment">
            <Select
              placeholder={'Segment'}
              options={options && [...options?.segment?.map(el=> ({label: el, value: el})), {label: 'All', value: 'none'}]}
              className='select-round'
              suffixIcon={<Icon type='arrow_down_df_select' role="icon" />}
              // allowClear
            />
          </Form.Item>
        </Col>
        <Col lg={4} xl={4}>
          <Form.Item name="rating">
            <Select
              placeholder={'Rating'}
              options={[...optionsRating, {label: 'All', value: 'none'}]}
              className='select-round'
              suffixIcon={<Icon type='arrow_down_df_select' role="icon" />}
              // allowClear
            />
          </Form.Item>
        </Col>
        {/* <Col lg={4} xl={4}>
          <Form.Item name="pricing">
            <Select
              placeholder={'Pricing'}
              options={null}
              className='select-round'
              suffixIcon={<Icon type='arrow_down_df_select' role="icon" />}
              // allowClear
            />
          </Form.Item>
        </Col> */}
        {/* <Col lg={4} xl={4}>
          <Form.Item name="segment2">
            {selectRound('Segment2', optionsAIS)}
          </Form.Item>
        </Col> */}
      </Row>

      <Row gutter={[24, 24]} justify="end"  align='center' style={{marginTop: -30}} className='clear-form-wrapper'>
        <Col span={1.5} style={{display: 'flex', alignItems: 'end'}}>
          <Text>
            {`${filtersNumber ? filtersNumber : 'No'} filter applied`}
          </Text>
        </Col>
        <Col span={1.5}>
          <Button
            type="link"
            className='clear-btn'
            style={{color: 'gray', fontWeight: 400, height: 20}}
            disabled={!filtersNumber}
            onClick={()=> {
              history.push('/catalog')
              // form.resetFields();
              form.setFieldsValue({'category': undefined, 'subcategory': undefined, 'search': undefined})
              setSearchQuery(null);
            }}
          >
            Clear All
          </Button>
        </Col>
      </Row>

    </Form>
  )
}

export default SearchForm
