import React from 'react'
import PropTypes from 'prop-types'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import {ConnectedRouter} from 'connected-react-router'
// import {GoogleOAuthProvider} from '@react-oauth/google'
import {ConfigProvider, App as AtndApp} from 'antd'
import enGB from 'antd/locale/en_GB'

import {history} from './Utils/utils'
import InitComponent from './Containers/InitComponent'

import myTheme from './theme.js'
import './index.less'
import './App.less'
import './Components/Forms/Form.less'
import './Containers/LoginPage/styles.less'

function App({store}) {
  return (
    <Provider store={store.store}>
      <PersistGate loading={null} persistor={store.persistor}>
        <ConfigProvider locale={enGB} theme={{
          ...myTheme,
          //algorithm: theme.darkAlgorithm,
        }}>
          <AtndApp>
            <ConnectedRouter history={history}>
              {/* <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}> */}
                <InitComponent/>
              {/* </GoogleOAuthProvider> */}
            </ConnectedRouter>
          </AtndApp>
        </ConfigProvider>
      </PersistGate>
    </Provider>
  )
}

App.propTypes = {
  store: PropTypes.shape({
    store: PropTypes.object,
    persistor: PropTypes.object,
  }).isRequired,
}

export default App
