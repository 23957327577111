import {createReducer, createActions} from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  getTableFiltersRequest: ['payload'],
  getTableFiltersSuccess: ['payload'],

  getTableDataRequest: ['payload'],
  getTableDataSuccess: ['payload'],

  tablePageFetchingReset: null,
  resetResults: null,
  // logoutSuccess: null,
})

export const TableTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  filters: null,
  leads: null,
  fetching: false,

  page_size_state: 10,
  page_state: 1,

})

/* ------------- Functions for reducer cases ------------- */
export const getTableFiltersRequest = (state) => {
  return state.merge({ fetching: true })
}
export const getTableFiltersSuccess = (state, { payload: filters }) => {
  return state.merge({ fetching: false, filters: filters?.data })
}

const getTableDataRequest = (state, {payload: query}) => {
    // console.log('query', query)
    const newState = {
      fetching: true
    };
    if (query.page) newState.page_state = query.page;
    if (query.page_size) newState.page_size_state = query.page_size;
    // if (query.ordering) newState.ordering = query.ordering;
    newState.search = query.q || '';
    return state.merge(newState)
}
const getTableDataSuccess = (state, {payload: data}) => {
  return state.merge({ fetching: false, leads: data })
}


const resetResults = (state, {}) =>
  state.merge({
    fileSendResult: null,
    getFileResult: null,
  })

const tablePageFetchingReset = (state) =>
  state.merge({
    fetching: false,
  })

// const logoutSuccess = (state) => INITIAL_STATE

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_TABLE_FILTERS_REQUEST]: getTableFiltersRequest,
  [Types.GET_TABLE_FILTERS_SUCCESS]: getTableFiltersSuccess,

  [Types.GET_TABLE_DATA_REQUEST]: getTableDataRequest,
  [Types.GET_TABLE_DATA_SUCCESS]: getTableDataSuccess,

  [Types.RESET_RESULTS]: resetResults,
  [Types.TABLE_PAGE_FETCHING_RESET]: tablePageFetchingReset,
  // [Types.LOGOUT_SUCCESS]: logoutSuccess,
})
