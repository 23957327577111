import React from 'react'
import PropTypes from 'prop-types'

export const Filters = ({width, height, color}) =>
  <svg width={width} height={height} viewBox="0 0 32 29" fill='none' xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.6667 17.6667C29.5077 17.6667 31 16.1743 31 14.3333C31 12.4923 29.5077 11 27.6667 11M27.6667 17.6667C25.8257 17.6667 24.3333 16.1743 24.3333 14.3333C24.3333 12.4923 25.8257 11 27.6667 11M27.6667 17.6667V27.6667M27.6667 11V1M16 21V1M4.33333 7.66667V27.6667M16 21C14.159 21 12.6667 22.4923 12.6667 24.3333C12.6667 26.1743 14.159 27.6667 16 27.6667C17.841 27.6667 19.3333 26.1743 19.3333 24.3333C19.3333 22.4923 17.841 21 16 21ZM4.33333 7.66667C6.17428 7.66667 7.66667 6.17428 7.66667 4.33333C7.66667 2.49238 6.17428 1 4.33333 1C2.49238 1 1 2.49238 1 4.33333C1 6.17428 2.49238 7.66667 4.33333 7.66667Z"
      stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
  </svg>

Filters.defaultProps = {
  width: 32,
  height: 29,
  color: '#000000'
}

Filters.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
}

export default Filters
