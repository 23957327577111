import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

const FbIcon = ({ width, height, className }) => (
  <svg
    width={width || "40"}
    height={height || "40"}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cls(className, 'icon icon-fb')}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40 19.9968C39.9994 16.1748 38.9037 12.433 36.8425 9.21443C34.7814 5.99584 31.8412 3.43522 28.3701 1.83571C24.8989 0.236197 21.0421 -0.335218 17.2562 0.189111C13.4704 0.71344 9.91407 2.31155 7.00827 4.79426C4.10247 7.27696 1.96889 10.5403 0.860108 14.1979C-0.248669 17.8555 -0.286207 21.7542 0.751937 25.4325C1.79008 29.1108 3.86044 32.4146 6.71789 34.9528C9.57535 37.491 13.1003 39.1573 16.8753 39.7544V25.7779H11.7976V19.9968H16.8753V15.5897C16.8753 10.5779 19.8612 7.80853 24.4282 7.80853C26.6165 7.80853 28.9059 8.19912 28.9059 8.19912V13.1215H26.3835C23.8988 13.1215 23.1247 14.6626 23.1247 16.2462V19.9968H28.6729L27.7859 25.7779H23.1271V39.7544C27.831 39.0088 32.1147 36.6094 35.2076 32.9876C38.3006 29.3659 39.9999 24.7595 40 19.9968Z"
      fill="#1877F2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.7851 25.78L28.6722 19.9988H23.1239V16.2482C23.1239 14.6647 23.9004 13.1235 26.3828 13.1235H28.9051V8.20114C28.9051 8.20114 26.6157 7.81055 24.4275 7.81055C19.8604 7.81055 16.8745 10.58 16.8745 15.5917V19.9988H11.7969V25.78H16.8745V39.7564C18.945 40.0814 21.0535 40.0814 23.1239 39.7564V25.78H27.7828"
      fill="white"
    />
  </svg>
);

FbIcon.defaultProps = {
  width: 40,
  height: 40,
};

FbIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

export default FbIcon;
