import React from 'react'
import PropTypes from 'prop-types'

export const Select = ({width, height, color}) =>
  <svg width={width} height={height} viewBox="0 0 13 17" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 5.63599L8.01179 1.62913C7.17685 0.790289 5.82315 0.790289 4.98821 1.62913L1 5.63599M1 11.364L4.98821 15.3709C5.82315 16.2097 7.17685 16.2097 8.01179 15.3709L12 11.364"
      stroke={color} strokeWidth="2" strokeLinecap="round"/>
  </svg>

Select.defaultProps = {
  width: 13,
  height: 17,
  color: '#555555'
}

Select.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
}

export default Select
