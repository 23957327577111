import React from 'react'
import PropTypes from 'prop-types'

export const Facebook = ({width, height, fill}) =>
  <svg width={width} height={height} viewBox="0 0 19 37" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.60666 19.7062H4.72965V36.4032C4.72965 36.7329 5.00119 37 5.33631 37H12.327C12.6621 37 12.9336 36.7329 12.9336 36.4032V19.7849H17.6734C17.9815 19.7849 18.2408 19.5574 18.276 19.2562L18.9959 13.1092C19.0157 12.9401 18.9612 12.7707 18.8461 12.6439C18.731 12.5169 18.5662 12.4442 18.3933 12.4442H12.9339V8.59092C12.9339 7.42936 13.5697 6.84034 14.8237 6.84034C15.0025 6.84034 18.3933 6.84034 18.3933 6.84034C18.7285 6.84034 19 6.57311 19 6.24357V0.60119C19 0.271532 18.7285 0.00441613 18.3933 0.00441613H13.4739C13.4392 0.00274516 13.3622 0 13.2486 0C12.395 0 9.42811 0.164829 7.08446 2.28576C4.48771 4.6361 4.84867 7.45025 4.93494 7.93817V12.4441H0.60666C0.271541 12.4441 0 12.7112 0 13.0408V19.1093C0 19.439 0.271541 19.7062 0.60666 19.7062Z"
    />
  </svg>

Facebook.defaultProps = {
  width: 19,
  height: 37,
  fill: '#FFFFFF'
}

Facebook.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
}

export default Facebook
