/* Dashboard services */

import { API } from '../../Services/Api';
// import {log} from '@craco/craco/lib/logger'

const request = new API();

export const getFileList = (params) => {
  return request.api.get(`/download/`, params);
}

export const sendFile = (params) => {
  return request.api.post(`/upload/`, params);
}

