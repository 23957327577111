import React, {useState } from 'react'
import { Row, Button, Typography, Col, Flex } from 'antd'
import Icon from '../../../Icon'

import Images from '../../../Images'
import BannerModal from './BannerModal'
import './banner2.less'

const { Title } = Typography;

const text = {
  1: {title: 'Drowning in data? Get insights that matter.', text: 'Submit the form and take the first step.', color: 'inherit'},
  2: {title: 'Stop guessing, start knowing.Book a call to optimize campaigns with data-driven strategies.', text: '', color: 'white'},
  3: {title: 'Optimize now, lead tomorrow: AI solutions streamline operations.', text:  'Arrange a call and talk to our experts.', color: 'white'},
  4: {title: 'Data overload? Simplify it with intelligent data management.', text: 'Fill out the form and make life easier!', color: 'white'},
  // 5: 'Innovation starts with AI. Want to automate data analysis? Schedule a call to transform data into knowledge!',
  // 6: 'Are you ready for the future of tech? This will keep you ahead of the curve. Complete the form for fast service.'
}

export const Banner2 = ({type, styleType, ...props}) => {

  const [openModal, setOpenModal] = useState(false)

  return (
    <div>
      <Row gutter={[0, 0]} align={'middle'} className={`banner-wrapper ${styleType}`}>
        <Col span={24} className='second-col-wrapper'>
          <Row align={'middle'}>
            <Col lg={24} xl={18}>
              <Flex>
                {type !== 4 &&
                  <Flex align='center'>
                    <img src={Images[`banner${type}`]} alt='' width={92} />
                  </Flex>
                }
                <Flex vertical justify='center'>
                  <Title level={1} style={{fontWeight: 300, margin: 0, marginLeft: type !== 4 ? 70 : 0, color: text[type].color}}>
                    {text[type].title}
                  </Title>
                  <Title level={2} className={`banner-gradient-text ${styleType}`} style={{fontWeight: 300, margin: 0, marginTop: 10, marginLeft: type !== 4 ? 70 : 0, color: text[type].color}}>
                    {text[type].text}
                  </Title>
                </Flex>
              </Flex>
            </Col>
            <Col lg={12} xl={6} style={{ textAlign: 'right' }}>
              <Button
                className='dark-blue-button'
                style={{width: '100%', maxWidth: 300, minWidth: 226}}
                onClick={()=> setOpenModal(true)}
              >
                <Row align={'middle'} justify={'space-between'}>
                  Get free consultation&nbsp;&nbsp;
                  <Icon type="getintouch" role="icon" />
                </Row>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <BannerModal isOpen={openModal} setOpenModal={setOpenModal} />
    </div>
  )
}

export default Banner2

