import React, {useState, useEffect, useRef} from 'react'
import {connect} from 'react-redux'
import {useLocation, useHistory} from 'react-router-dom'
import PropTypes from 'prop-types'
import Creators from './reducer'
import {debounce, openHideLink} from "../../Utils/utils"
import {Pagination, Input, Row, Button, Typography, Col,  Divider, Spin, Flex } from 'antd'
import SearchForm from '../../Components/Forms/SearchForm'
import { metaDataJson } from './metaData'
import { Helmet } from 'react-helmet';

import Images from '../../Images'
import {colors} from '../../colors'
import './styles.less'
import Footer from './components/Footer'
import Banner from './components/banner'
import Banner2 from './components/banner2'

const { Text, Title } = Typography;

const fakeImagesUrls = [
  {foto: Images.example1, title: 'The Cumulative Impact of Having the Wrong Name'},
  {foto: Images.example2, title: 'How Today`s AI Art Debate Will Shape the Creative Landscape'},
  {foto: Images.example3, title: 'Agile Projects Have Become Waterfall Projects With Sprints'},
]

const defaultOptionsCategory = ["Analytics Tools & Software", "Artificial Intelligence", "IT Infrastructure"]
const defaultOptionsSubCategory = [
  "Analytics Platforms",
  "Business Intelligence Software",
  "Conversational Intelligence Software",
  "DDI Management Platform",
  "Data Center Infrastructure Management (DCIM) Software",
  "Data Center Networking Solutions",
  "Data Exchange Platforms",
  "Data Fabric Software",
  "Data Integration Tools",
  "Data Mapping Software",
  "Data Preparation Software",
  "Data Quality Tools",
  "Data Science and Machine Learning Platforms",
  "Data Virtualization Software",
  "Data Warehouse Automation Software",
  "Data Warehouse Solutions",
  "DataOps Platforms",
  "Database Software",
  "Deep Learning Software",
  "Desktop as a Service (DaaS) Providers",
  "Generative AI Software",
  "Guest Wi-Fi Providers",
  "Hyperconverged Infrastructure (HCI) Solutions",
  "Infrastructure as a Service (IaaS) Providers",
  "Load Balancing Software",
  "Machine Learning Data Catalog Software",
  "Machine Learning Software",
  "Managed Workplace Services (MWS) Software",
  "Master Data Management (MDM) Tools",
  "Monitoring Software",
  "Network Management Tools",
  "Operating Systems",
  "Other Analytics Software",
  "Other Cloud Integration Software",
  "Other IT Infrastructure Software",
  "Predictive Analytics Software",
  "Remote Desktop Software",
  "Remote Support Software",
  "Server Monitoring Software",
  "Server Virtualization Software",
  "Statistical Analysis Software",
  "Storage Management Software",
  "Synthetic Data Software",
  "Text Analysis Software",
  "Time Series Intelligence Software",
  "Video Surveillance Software",
  "Virtual Desktop Infrastructure (VDI) Software",
  "Virtual Private Cloud (VPC) Software",
  "Virtual Routers",
  "Visitor Behavior Intelligence Software",
  "WAN Edge Infrastructure Software",
  "WAN Optimization Software",
  "Web Accelerator Software",
  "Web Data Providers Software"
];


const TextBlock = ({ text, id }) => {
  const textRef = useRef(null);

  const [isTextOverflowed, setIsTextOverflowed] = useState(false);
  const [showFullText, setShowFullText] = useState({id: null, view: false});

  const toggleText = (id) => {
    setShowFullText(prev => prev?.view === 'less' ? {id, view: false} : {id, view: 'less'});
  };

  useEffect(() => {
    const textElement = textRef.current;
    if (textElement) {
      setIsTextOverflowed(
        textElement.scrollHeight > textElement.clientHeight ||
        textElement.scrollWidth > textElement.clientWidth
      );
    }
  }, [text, id]);

  return (
    <>
    <Text
      ref={textRef}
      style={{fontSize: 16, color: colors.mainDark, marginTop: 4, textAlign: 'justify'}}
      className={(showFullText?.id === id && showFullText?.view === 'less') ? '' : 'text-block'}
    >
      {text?.length > 540 ? text.substring(0, 540) + "..." : text}
    </Text>
      {isTextOverflowed &&
        <Button type='text' onClick={()=> toggleText(id)}>
          {(showFullText?.id === id && showFullText?.view === 'less') ? 'Read less' : 'Read more' }
        </Button>
      }
    </>
  );
};

const CategoryList = ({options}) => {
  const location = useLocation();
  const pathnameArr = location.pathname.split("/");

  const categorySelected = pathnameArr[2];
  const isSubcategorySelected = !!pathnameArr[3];
  const itemsToDisplay = categorySelected ? options?.subcategory : options?.category;
  return (
      <div style={{marginTop: 20}}>
          {itemsToDisplay?.map((item, index) => {
            return (
              <div key={index}>
                {!!categorySelected ?
                    <a href={`https://dataforest.ai/catalog/${categorySelected}/${item.toLowerCase().replaceAll(/\s/g, "-")}`}>{item}</a>
                    // <a href={`http://localhost:3000/catalog/${categorySelected}/${item.toLowerCase().replaceAll(/\s/g, "-")}`}>{item}</a>
                  :
                    <a href={`https://dataforest.ai/catalog/${item.toLowerCase().replaceAll(/\s/g, "-")}`}>{item}</a>
                    // <a href={`http://localhost:3000/catalog/${item.toLowerCase().replaceAll(/\s/g, "-")}`}>{item}</a>
                }
              </div>
          )}
          )}
      </div>
  );
};

const defineOptions = (arr) => {
  return [...arr?.map(el=> ({label: el, value: el.toLowerCase().replaceAll(/\s/g, "-")})), {label: 'All', value: 'none'}]
}

export const CatalogPage = (props) => {

  const {fetching, page_size_state, page_state, count, catalog, options, filterBy, metaDataObj } = props;

  const { pathname } = useLocation();
  const history = useHistory();
  const helpRef = useRef({firstLoaging: true});

  const [searchQuery, setSearchQuery] = useState(null);

  //for metaData
  // const [metaDataObj, setMetaDataObj] = useState(null);

  const [optionsCategories, setOptionsCategories] = useState(null)
  const [optionsSubCategories, setOptionsSubCategories] = useState(null)

  const pathnameArr = pathname.split('/').filter(Boolean);
  const categoryInUrl = pathnameArr[1];
  const subCategoryInUrl = pathnameArr[2];

  useEffect(()=> {
    if (options) {
      const newOptionsCategories = defineOptions(options?.category)
      const newOptionsSubCategories = defineOptions(options?.subcategory)
      setOptionsCategories(newOptionsCategories)
      setOptionsSubCategories(newOptionsSubCategories)
    }
  }, [options])

   //for metaData
   const formatName = (category) => {
    switch(category) {
      case 'IT Infrastructure': return 'IT Infrastructure Software';
      case 'Artificial Intelligence': return 'Artificial Intelligence Software';
      // case 'Analytics Tools & Software': return 'Analytics Tools & Software';
      default: return category;
    }
  }

  //for metaData
  const findMetaDataObj = (category, subCategory) => {
    let selectedOptionCategory;
    let selectedOptionSubCategory;
    if (optionsCategories || optionsSubCategories) {
      selectedOptionCategory = optionsCategories?.find(option => option.value === category);
      selectedOptionSubCategory = optionsSubCategories?.find(option => option.value === subCategory);
    } else if (categoryInUrl || subCategoryInUrl) {
      const optionsCategories = defineOptions(defaultOptionsCategory);
      const optionsSubCategories = defineOptions(defaultOptionsSubCategory);
      selectedOptionCategory = optionsCategories?.find(option => option.value === category);
      selectedOptionSubCategory = optionsSubCategories?.find(option => option.value === subCategory);
    }
    if (category && !subCategory) {
      let obj = metaDataJson?.filter(el => (el['Group'] === formatName(selectedOptionCategory?.label)))[0]
      props.setMetaDataObj(obj)
    }
    if (!category) props.setMetaDataObj(null)

    if (category && subCategory) {
      let obj = metaDataJson?.filter(el => (el['Category'] === formatName(selectedOptionSubCategory?.label)))[0]
      props.setMetaDataObj(obj)
    }
  }

  const handleFindMetaData = (category, subcategory) => {
    if (category && !subcategory) findMetaDataObj(category, null);
    if (subcategory) findMetaDataObj(category, subcategory);
    if (!category) props.setMetaDataObj(null)
  }

  const handleQuery = (page) => {
    const updatedFilterBy = {};
    if (optionsCategories || optionsSubCategories) {
      //update filterby to send Label fronm options
      for (const key in filterBy) {
        const selectedOption = optionsCategories.concat(optionsSubCategories).find(option => option.value === filterBy[key]);
        if (selectedOption) {
          updatedFilterBy[key] = selectedOption.label;
        }
      }
      handleFindMetaData(filterBy.category, filterBy.subcategory)
    }
    else if (categoryInUrl || subCategoryInUrl) {
      const optionsCategories = defineOptions(defaultOptionsCategory);
      const optionsSubCategories = defineOptions(defaultOptionsSubCategory);
      for (const key in filterBy) {
        const selectedOption = optionsCategories.concat(optionsSubCategories).find(option => option.value === filterBy[key]);
        if (selectedOption) {
          updatedFilterBy[key] = selectedOption.label;
        }
      }
      handleFindMetaData(categoryInUrl, subCategoryInUrl)
    }

    let query = {
      q: searchQuery,
      page_size: page_size_state,
      page: page,
      // ordering: sortField,
      ...updatedFilterBy
    };

    for (let key in query) {
      if (!query[key]) {
        delete query[key];
      }
    }
    // console.log(query)
    props.getCatalogData(query);
  }

  const onPaginate = (page, page_size) => {
    handleQuery(page);
  }

  const handleSearch = debounce((value) => {
    let val = value.trim().toLowerCase()
    if (val !== '' && val.length) setSearchQuery(val)
    else setSearchQuery(null)
  }, 500)

  // const onShowSizeChange = (current, pageSize) => {
  //   setPaginate({
  //     page: 1,
  //     page_size: pageSize
  //   });
  // };

  // const changeFilter = (field, value) => {
  //   setFilterBy({
  //     ...filterBy,
  //     [field]: value
  //   })
  // }

  const handleChangeFilter = (data) => {
    props.changeFilter(data)
  }

  useEffect(() => {
    document.title = 'Dataforest - Catalog'
  }, [])

  useEffect(() => {
    if (helpRef.current.firstLoaging) {
      helpRef.current.firstLoaging = false
    } else {
      handleQuery(1);
    }
  }, [searchQuery, filterBy.category, filterBy.subcategory, filterBy.segment, filterBy.rating])

  return (
    <div className='catalog-page'>
      <Helmet>
        <title>{metaDataObj?.['Title'] || 'Business and Data Software Reviews'}</title>
        <meta name="description" content={metaDataObj?.['Description'] || "Benefits of Video Conferencing Software with Presentations capabilities include: Presenters can cue up a Powerpoint presentation or other slideshow for display in the conference. Below are the top-rated Video Conferencing Software with Presentations capabilities, as verified by G2`s Research team. Real users have identified Presentations as an important function of Video Conferencing Software. Compare different products that offer this feature so you can decide which is best for your business needs."} />
      </Helmet>
      <Text style={{fontSize: 16}} className='breadcrumb-item uderline' onClick={()=>openHideLink('https://dataforest.ai/')}>Home page</Text> /
      <Text style={{fontSize: 16}}>Catalog</Text>
      <Title className='main-title'>{metaDataObj?.['H1'] || 'Business and Data Software Reviews'}</Title>
      {!metaDataObj?.['H1'] ?
         <Text className='text-gradient'>We provide custom data-driven solutions for SMBs, evolving efficiency with revenue growth and cost reduction.</Text>
        :  <Text className='text-gradient'>{metaDataObj?.['Description']}</Text>
      }
      <Row>
        <Text style={{fontSize: 16, textAlign: 'justify'}}>
          {
            metaDataObj?.['Text'] || 'Our suite of reviews offers an analysis platform, spotlighting efficacy and innovation. Analytics tools, artificial intelligence software, and IT infrastructure software are designed to elevate business intelligence. This review collection provides insights and actionable feedback to refine data management strategies and technological deployment.'
          }
        </Text>
      </Row>
      <div style={{minHeight: 174}}>
        <Row className='select-wrapper' style={{marginTop: 65}}>
          <div className='line line-left'></div>
          <div className='line line-right'></div>

          <SearchForm
            // setFilterBy={setFilterBy}
            setFilterBy={handleChangeFilter}
            filterBy={filterBy}
            handleSearch={handleSearch}
            options={options}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            optionsCategories={optionsCategories}
            optionsSubCategories={optionsSubCategories}
          />
        </Row>
      </div>

      <Row style={{marginTop: 31, marginBottom: 11}}>
        <Text style={{fontSize: 22, fontWeight: 700, color: '#0C0838'}}>{count}</Text>&nbsp;&nbsp;
        <Text style={{fontSize: 22, color: '#0C0838'}}>Listings in ETL Tools Available</Text>
      </Row>

      <Spin spinning={fetching}>
        {catalog?.length ?
        catalog?.map((el, i )=> (
          <div key={el?.id}>
          <Row gutter={[0, 0]} className='company-wrapper' onClick={()=> history.push(`/catalog/company/${el?._id}`)}>
            <Col span={6} className='fst-col-wrapper'>
                <img src={el.logo} alt='' />
                <Text
                  className='company-name'
                  onClick={()=> history.push(`/catalog/company/${el?._id}`)}
                >{el?.title}</Text>
                <Row style={{marginTop: 9}}>
                  {[...Array(Math.round(el?.rating || 0)).keys()].map((el, i) => (<img key={i} src={Images.star} alt='' style={{marginRight: 5}}/>))}
                  <Text style={{color: colors.gray2}}>&nbsp;{`${el?.rating_count || 0} reviews`}</Text>
                </Row>
                <Text style={{marginTop: 9, fontWeight: 700, color: colors.mainDark}}>
                  {el?.rating || 0}&nbsp;
                  <Text style={{fontWeight: 400}}>out of&nbsp;</Text>5
                </Text>
            </Col>
            <Col span={18} className='second-col-wrapper'>
              <Text style={{fontSize: 18, color: colors.mainDark, fontWeight: 500}}>Overview</Text>
              <TextBlock text={el?.overview_description} id={el?.id} />
              <Row style={{marginTop: 29}}>
                <Col span={8}>
                  <div><img src={Images.users} alt=""/></div>
                  <Text style={{marginTop: 9, fontSize: 18, lineHeight: '28px'}}>Users</Text>
                  <ul style={{marginLeft: 14}}>
                    {el?.overview_users?.map((el, i)=> (
                        <li className='list-item' key={i}>{el}</li>
                      ))
                    }
                    {/* <li className='list-item'>Information Technology and Services</li>
                    <li className='list-item'>Computer Software</li> */}
                  </ul>
                </Col>
                <Col span={8}>
                  <div><img src={Images.industries} alt=""/></div>
                  <Text style={{marginTop: 9, fontSize: 18}}>Industries</Text>
                  <ul style={{marginLeft: 14}}>
                    {el?.overview_industries?.map((el, i)=> (
                        <li className='list-item' key={i}>{el}</li>
                      ))
                    }
                  </ul>
                </Col>
                <Col span={8}>
                  <div><img src={Images.market} alt=""/></div>
                  <Text style={{marginTop: 9, fontSize: 18}}>Market Segment</Text>
                  <ul style={{marginLeft: 14}}>
                    {el?.overview_market_segment?.map((el, i)=> (
                        <li className='list-item' key={i}>{el}</li>
                      ))
                    }
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
           {/* {i === 12 && <Banner key='banner'/>} */}
           {/* {i !== 0 && i % 5 === 0 && <Banner key='banner'/>} */}
           {i === 4 && <Banner2 key={'banner' + i} type={4} styleType={'four'} />}
           {i === 9 && <Banner2 key={'banner' + i} type={1} styleType={'first'} />}
           {i === 14 && <Banner2 key={'banner' + i} type={2} styleType={'second'} />}
           {i === 19 && <Banner2 key={'banner' + i} type={3} styleType={'third'} />}
          </div>
        ))
        : <div className='no-results'>No results, try another filter</div>
        }

        <div className='pagination-wrapper'>
          {catalog?.length ?
            <Pagination
              onChange={(page, page_size) => onPaginate(page, page_size)}
              showSizeChanger={false}
              // onShowSizeChange={onShowSizeChange}
              current={page_state}
              total={count}
              pageSize={page_size_state}
              style={{marginTop: 59}}
            />
            : null
          }
        </div>
        <CategoryList options={options} />
      </Spin>
      <Footer />
    </div>
  )
}

CatalogPage.defaultProps = {
  fetching: false,
  catalog: null,
  page_state: 1,
  page_size_state: 24,
  count: null,
}

CatalogPage.propTypes = {
  fetching: PropTypes.bool,
  catalog: PropTypes.array,
  page_state: PropTypes.number,
  page_size_state: PropTypes.number,
  count: PropTypes.number,
}

const mapStateToProps = (state) => ({
  fetching: state.catalog.fetching,
  filterBy: state.catalog.filterBy,
  page_size_state: state.catalog.page_size_state,
  page_state: state.catalog.page_state,
  catalog: state.catalog.catalog,
  options: state.catalog.options,
  count: state.catalog.count,
  metaDataObj: state.catalog.metaDataObj,
})

const mapDispatchToProps = (dispatch) => ({
  // getTableFilters: () => dispatch(Creators.getTableFiltersRequest()),
  getCatalogData: (params) => dispatch(Creators.getCatalogDataRequest(params)),
  changeFilter: (data) => dispatch(Creators.changeFilter(data)),
  setMetaDataObj: (data) => dispatch(Creators.setMetaDataObj(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CatalogPage)
