import React, {useState, useEffect, useRef} from 'react'
import { useSelector } from "react-redux"
import { useParams, Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import { Row, Typography, Col, Tabs,  Divider, Flex } from 'antd'


import Images from '../../../Images'
import {colors} from '../../../colors'

import './companyPage.less'
import Footer from './Footer'
import { scrollToTop } from '../../../Utils/utils'

const { Text, Title } = Typography;

const tabsItems = [
  {
    key: 'product',
    label: <div>Product information</div>,
    // anchor: 'product',
  },
  /*{
    key: 'pricing',
    label: <div>Pricing</div>,
    anchor: 'pricing',
  },
  {
    key: 'features',
    label: <div>Features</div>,
    anchor: 'features',
  },*/
]

export const CompanyPage = ({fetching, ...props}) => {

  const { id } = useParams()
  const { catalog } = useSelector(state => state.catalog)
  // console.log(catalog)
  const [companyData, setCompanyData] = useState(null);
  const [topRatedData, setTopRated] = useState(null);
  const [activeTab, setActiveTab] = useState('product')

  const onChangeTabs = (key) => {
    setActiveTab(key);
    const anchor = tabsItems.find(item => item.key === key)?.anchor;
    if (anchor) {
      const element = document.getElementById(anchor);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  useEffect(() => {
    document.title = 'Dataforest - Catalog/Company'
    scrollToTop('scroll-container')
    if (catalog) {
      const data = catalog?.filter(el => el._id === id)[0];
      setCompanyData(data)
      const topRated = [...catalog]?.filter(el=> el?.rating >= 4.0)?.sort((a, b) => b.rating - a.rating)?.slice(0, 6);
      setTopRated(topRated)
    }
  }, [catalog])

  return (
    <div className='company-page'>
      <Link to={'/catalog'} style={{display: 'block', marginBottom: 30, color: colors.link}}>{'<- Return to Catalog'}</Link>
      {companyData &&
        <Row gutter={[42, 32]} className='company-wrapper'>
          <Col span={6} className='fst-col-wrapper'>
              <Flex justify='start'>
                <img src={companyData?.logo} alt='' width={122}/>
              </Flex>
              <Title className='page-titlle company-name'>
                {companyData?.title}
              </Title>
              <Row style={{marginTop: 9}}>
                {[...Array(Math.round(companyData?.rating))?.keys()]?.map((el, i) => (<img key={i} src={Images.star} alt='' style={{marginRight: 5}}/>))}
                <Text style={{color: colors.gray2}}>&nbsp;{`${companyData?.rating_count} reviews`}</Text>
              </Row>
              <Text style={{marginTop: 9, fontWeight: 700, color: colors.mainDark}}>
                {companyData?.rating}&nbsp;
                <Text style={{fontWeight: 400}}>out of&nbsp;</Text>5
              </Text>
          </Col>
          <Col span={18} className='second-col-wrapper'>
            <Flex align='end' style={{height: '100%'}}>
              <Row gutter={10} style={{width: '100%'}}>
                <Col span={8}>
                  <div><img src={Images.users} alt=""/></div>
                  <Text style={{marginTop: 9, fontSize: 18, lineHeight: '28px'}}>Users</Text>
                  <ul style={{marginLeft: 14}}>
                    {companyData?.overview_users?.map((el, i)=> (
                        <li className='list-item' key={i}>{el}</li>
                      ))
                    }
                  </ul>
                </Col>
                <Col span={8}>
                  <div><img src={Images.industries} alt=""/></div>
                  <Text style={{marginTop: 9, fontSize: 18}}>Industries</Text>
                  <ul style={{marginLeft: 14}}>
                    {companyData?.overview_industries?.map((el, i)=> (
                        <li className='list-item' key={i}>{el}</li>
                      ))
                    }
                  </ul>
                </Col>
                <Col span={8}>
                  <div><img src={Images.market} alt=""/></div>
                  <Text style={{marginTop: 9, fontSize: 18}}>Market Segment</Text>
                  <ul style={{marginLeft: 14}}>
                    {companyData?.overview_market_segment?.map((el, i)=> (
                        <li className='list-item' key={i}>{el}</li>
                      ))
                    }
                  </ul>
                </Col>
              </Row>
            </Flex>
          </Col>
      </Row>}

      <Row>
        <Col span={24}>
          <Tabs
            activeKey={activeTab}
            items={tabsItems}
            onChange={onChangeTabs}
            // centered
            className='company-tabs'
          />
        </Col>
      </Row>

      <Row>
        <Title className='page-titlle section-title'>{`Top rated ${companyData?.title} Alternatives`}</Title>
      </Row>

      <Row gutter={[32,32]}>
        {topRatedData?.length &&
          topRatedData?.map(company => {
            return (
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                <Flex className='top-rated-wrapper'>
                  <Flex justify='center' align='center' className='logo-wrapper'>
                    <img src={company?.logo} alt='' width={54}/>
                  </Flex>
                  <Flex vertical style={{marginLeft: 20}}>
                    <Title className='page-titlle top-rated-card-title'>{company?.title}</Title>
                    <Row style={{marginTop: 9}}>
                      {[...Array(Math.round(company?.rating))?.keys()]?.map((el, i) => (<img key={i} src={Images.star} alt='' style={{marginRight: 5}}/>))}
                      <Text style={{color: colors.gray2}}>&nbsp;{`${company?.rating_count} reviews`}</Text>
                    </Row>
                    <Text style={{marginTop: 9, fontWeight: 700, color: colors.mainDark}}>
                      {company?.rating}&nbsp;
                      <Text style={{fontWeight: 400}}>out of&nbsp;</Text>5
                    </Text>
                  </Flex>
                </Flex>
              </Col>
            )
          })
        }
      </Row>

      <Divider  className='divider-fullwidth'/>

      {/* <Row>
        <Title className='page-titlle section-title overview'>{`${companyData?.title} Overview`}</Title>
      </Row> */}

      <Row>
        <Col span={24}
             //span={10} className='overview-block'
        >
          <Title className='page-titlle section-title overview' id='product'>{`${companyData?.title} Overview`}</Title>
          <Title className='page-titlle section-title overview-sub-title'>{`What is ${companyData?.title}?`}</Title>
          <div className='overview-text'>
            <Text>
              {companyData?.overview_description}
            </Text>
          </div>
        </Col>
        {/*<Col span={14} className='overview-block right'>
          <Title className='page-titlle section-title overview'>&nbsp;</Title>
          <Title className='page-titlle section-title overview-sub-title'>Languages Supported</Title>
          <div className='overview-text'>
            <Text>
              Afrikaans, Arabic, Assamese, Azerbaijani, Belarusian, Bengali, Bulgarian, Cebuano, Czech, Welsh, Danish, German, Greek, English,
              Estonian, Basque, Finnish, French, Galician, Gujarati, Hebrew, Hindi, Croatian, Hungarian, Armenian, Indonesian, Italian, Javanese,
              Japanese, Kannada, Georgian, Kazakh, Central Khmer, Korean, Kurdish, Latvian, Lithuanian, Malayalam, Marathi, Macedonian, Mongolian,
              Malay, Burmese, Nepali, Dutch, Norwegian, Punjabi, Polish, Portuguese, Romanian, Russian, Sinhala, Slovak, Slovenian, Somali, Spanish,
              Albanian.
            </Text>
          </div>
        </Col>*/}
      </Row>
      <Divider  className='divider-fullwidth' style={{marginTop: 0}}/>

      {/*<Row>
        <Col>
          <Title className='page-titlle section-title overview' style={{marginBottom: 14}}>
            Product Description
          </Title>
          <Text style={{ fontSize: 22, fontWeight: 300}}>
            {`${companyData?.title} is an online design and publishing platform that provides user friendly design tools for non-designers.`}
            </Text>
        </Col>
      </Row>*/}
      {/*<Row>
        <Col span={10} className='overview-block no-border'>
          <Title strong className='page-titlle section-title overview-sub-title'>Description</Title>
          <div className='overview-text'>
            <Text>
              {`Before ${companyData?.title}, creating a professional looking design was a complex process - you had to purchase expensive software;
              learn how to use it; purchase stock photography and fonts for the software; decide on a layout; slice images; receive
              photos and content via email; design something; upload and email the pdf only to find revisions needed to be made … and
              then finally be able to prepare your design for web or print.`}
            </Text>
          </div>
        </Col>

        <Col span={14} className='overview-block right'>
          <Title strong className='page-titlle section-title overview-sub-title'>Seller</Title>
          <Row>
            <Col span={12}>
              <Flex vertical gap={30}>
                <Flex align='start'>
                  <img src={Images.user} alt=''/>
                  <Flex vertical style={{paddingLeft: 30}}>
                    <Text className='seller-text bold'>Seller</Text>
                    <Text className='seller-text'>{companyData?.title}</Text>
                  </Flex>
                </Flex>
                <Flex align='start'>
                  <img src={Images.calendar} alt=''/>
                  <Flex vertical style={{paddingLeft: 30}}>
                    <Text className='seller-text bold'>Year Founded</Text>
                    <Text className='seller-text'>{companyData?.year || '2012'}</Text>
                  </Flex>
                </Flex>
                <Flex align='start'>
                  <img src={Images.location} alt=''/>
                  <Flex vertical style={{paddingLeft: 30}}>
                    <Text className='seller-text bold'>HQ Location</Text>
                    <Text className='seller-text'>{companyData?.location || 'Syndey'}</Text>
                  </Flex>
                </Flex>

              </Flex>

            </Col>
            <Col span={12}>
              //<Flex vertical gap={30}>
                 //<Flex align='start'>
                  //<img src={Images.fb2} alt=''/>
                  //<Flex vertical style={{paddingLeft: 30}}>
                    //<Text className='seller-text bold'>Facebook</Text>
                    //<Text className='seller-text'>{companyData?.facebook || '@'}</Text>
                  //</Flex>
                //</Flex>
                <Flex align='start'>
                  <img src={Images.twitter} alt=''/>
                  <Flex vertical style={{paddingLeft: 30}}>
                    <Text className='seller-text bold'>Twitter</Text>
                    <Text className='seller-text'>{companyData?.twitter || '@canva'}</Text>
                    <Text className='seller-text'>{companyData?.twitter_followers || '03,284 Twitter followers'}</Text>
                  </Flex>
                </Flex>
                <Flex align='start'>
                  <img src={Images.linked2} alt=''/>
                  <Flex vertical style={{paddingLeft: 30}}>
                    <Text className='seller-text bold'>LinkedIn Page</Text>
                    <Text className='seller-text'>{companyData?.linkedin_link || 'www.linkedin.com'}</Text>
                    <Text className='seller-text'>{companyData?.linkedin_employees || '11,957 employees on LinkedIn®'}</Text>
                  </Flex>
                </Flex>
              </Flex>
            </Col>
          </Row>
        </Col>
      </Row>*/}

      {/*<Divider  className='divider-fullwidth' style={{marginTop: 76}}/>

      <Row>
        <Col>
          <Title className='page-titlle section-title overview' style={{marginBottom: 33}} id="pricing">
            {`${companyData?.title} Pricing`}
          </Title>
        </Col>
      </Row>

      <Row gutter={[32, 32]}>
        {[{name: 'Free', price: 0, term: 'Forever'}, {name: 'Pro', price: 12.5, term: 'Per user per month'}, {name: 'Enterprice', price: 36, term: 'Per user per month'}]
        ?.map(plan => (
          <Col span={8}>
            <Flex vertical className='pricing-item'>
              <Flex justify='center' align='center' className='gray-block'>{plan?.name}</Flex>
              <Flex align='center' justify='center' vertical className='pricing-item'>
                <Text style={{fontSize: 54}}>{plan?.price}</Text>
                <Text style={{fontSize: 22, fontWeight: 300}}>{plan?.term}</Text>
              </Flex>
            </Flex>
          </Col>
        ))
        }
      </Row>*/}

      {/*<Divider  className='divider-fullwidth' style={{marginTop: 66}}/>

      <Row>
        <Col>
          <Title className='page-titlle section-title overview' style={{marginBottom: 33}} id='features'>
            {`${companyData?.title} Features`}
          </Title>
          <Text style={{ fontSize: 22, fontWeight: 300}}>
            {`What are the features of ${companyData?.title}`}
            </Text>
        </Col>
      </Row>

      <Row gutter={[32, 32]}>
        {[{name: 'Template creation', items: ['Image Upload', 'Stock Image Library', 'Drag & Drop Editing', 'Variety Of Banner Types']},
          {name: 'Design & Editing', items: ['Shape & Clip Art', 'Font Selection', 'Scale', 'Collaboration']},
          {name: 'Ad Management', items: ['Ad Sets', 'Export As Different File Types', 'Schedule', 'Template Usage']}]
        ?.map(feature => (
          <Col span={8}>
            <Flex vertical gap={30} className='features-item'>
              <Text strong style={{fontSize: 22}}>{feature?.name}</Text>
              {feature?.items?.map(item => (
                  <Flex gap={16}>
                    <img src={Images.checkmark} alt=''/>
                    <Text style={{fontSize: 16}}>{item}</Text>
                  </Flex>
                ))
              }
            </Flex>
          </Col>
        ))
        }
      </Row>*/}

      <Divider  className='divider-fullwidth' style={{marginTop: 45, marginBottom: 80}}/>

      <Footer />


    </div>
  )
}

CompanyPage.defaultProps = {
  fetching: false,
}

CompanyPage.propTypes = {
  fetching: PropTypes.bool,
}


export default CompanyPage
