import React from 'react'
import PropTypes from 'prop-types'
import cls from 'classname'

export const Calendar = ({width, height, fill, className}) => (
  <svg width={width}
       height={height}
       viewBox="0 0 18 20"
       fill={fill}
       className={cls(className, 'icon')}
       xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd"
          d="M12 2V1C12 0.448 12.448 0 13 0C13.552 0 14 0.448 14 1V2C16.209 2 18 3.791 18 6V16C18 18.209 16.209 20 14 20C11.14 20 6.859 20 4 20C1.791 20 0 18.209 0 16V6C0 3.791 1.791 2 4 2V1C4 0.448 4.448 0 5 0C5.552 0 6 0.448 6 1V2H12ZM16 10H2V16C2 17.104 2.895 18 4 18H14C15.104 18 16 17.104 16 16V10ZM14 4V5C14 5.552 13.552 6 13 6C12.448 6 12 5.552 12 5V4H6V5C6 5.552 5.552 6 5 6C4.448 6 4 5.552 4 5V4C2.895 4 2 4.895 2 6V8H16V6C16 4.895 15.104 4 14 4Z"
          />
  </svg>
)

Calendar.defaultProps = {
  width: 18,
  height: 20,
  fill: '#98A2B3',
}

Calendar.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
}

export default Calendar
