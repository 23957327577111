/* Dashboard services */

import { API } from '../../Services/Api';
// import {log} from '@craco/craco/lib/logger'

const request = new API();

// export const getTableFilters = (params) => {
//   return request.api.get(`/for_filter/`, params);
// }

export const getCatalogData = (params) => {
  return request.api.get(`/`, params);
}
