import {createReducer, createActions} from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({

  getCatalogDataRequest: ['payload'],
  getCatalogDataSuccess: ['payload'],

  catalogPageFetchingReset: null,
  resetResults: null,
  changeFilter: ['payload'],
  setMetaDataObj: ['payload'],
  // logoutSuccess: null,
})

export const CatalogTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  filters: null,
  catalog: null,
  options: null,
  fetching: false,

  page_size_state: 24,
  page_state: 1,
  count: null,

  filterBy: {
    category: null,
    subcategory: null,
    segment: null,
    rating: null,
  },

  metaDataObj: null,

})

/* ------------- Functions for reducer cases ------------- */

const getCatalogDataRequest = (state, {payload: query}) => {
    // console.log('query', query)
    const newState = {
      fetching: true
    };
    if (query.page) newState.page_state = query.page;
    if (query.page_size) newState.page_size_state = query.page_size;
    // if (query.ordering) newState.ordering = query.ordering;
    // newState.search = query.q || '';
    return state.merge(newState)
}
const getCatalogDataSuccess = (state, {payload: data}) => {
  return state.merge({ fetching: false, catalog: data.result, options: data.options, count: data.count })
}

const resetResults = (state, {}) =>
  state.merge({
  })

const catalogPageFetchingReset = (state) =>
  state.merge({
    fetching: false,
  })

const changeFilter = (state, {payload: data}) => {
  return state.merge({ filterBy: data })
}

const setMetaDataObj = (state, {payload: data}) => {
  return state.merge({ metaDataObj: data })
}

// const logoutSuccess = (state) => INITIAL_STATE

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

  [Types.GET_CATALOG_DATA_REQUEST]: getCatalogDataRequest,
  [Types.GET_CATALOG_DATA_SUCCESS]: getCatalogDataSuccess,

  [Types.RESET_RESULTS]: resetResults,
  [Types.CATALOG_PAGE_FETCHING_RESET]: catalogPageFetchingReset,
  [Types.CHANGE_FILTER]: changeFilter,
  [Types.SET_META_DATA_OBJ]: setMetaDataObj,
  // [Types.LOGOUT_SUCCESS]: logoutSuccess,
})
