import { takeLatest, takeEvery, put, call } from 'redux-saga/effects';

import * as services from './services';
import Creators, { TableTypes as constants } from './reducer';
import ErrorsActions from '../../Redux/ErrorsRedux'

const actions = {
  getTableDataActions: {
    request: Creators.getTableDataRequest,
    success: Creators.getTableDataSuccess,
  },
  getTableFiltersActions: {
    request: Creators.getTableFiltersRequest,
    success: Creators.getTableFiltersSuccess,
  },
};

const eventsOptions = {
  [constants.GET_TABLE_DATA_REQUEST]: {
    api: services.getTableData,
    actions: actions.getTableDataActions,
  },
  [constants.GET_TABLE_FILTERS_REQUEST]: {
    api: services.getTableFilters,
    actions: actions.getTableFiltersActions,
  },
};

function* apiGenerator(action) {
  const provider = eventsOptions[action.type];
  try {
    const params = action.payload;
    const response = yield call(provider.api, params);

    if (response.ok && response.data) {
      yield put(provider.actions.success(response.data));
    } else {
      yield put(Creators.tablePageFetchingReset());
    }
  } catch (errors) {
    yield put(Creators.tablePageFetchingReset());
  }
}

export default function* apiSaga() {
  // yield takeLatest(constants.GET_TABLE_DATA_REQUEST, apiGenerator);
  // yield takeLatest(constants.GET_TABLE_FILTERS_REQUEST, apiGenerator);
}
