import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

const GoogleIcon = ({ width, height, className }) => (
  <svg
    width={width || "40"}
    height={height || "40"}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cls(className, 'icon icon-google')}
  >
    <path
      d="M8.86484 24.173L7.4725 29.3709L2.38352 29.4785C0.862656 26.6577 0 23.4302 0 20.0005C0 16.6841 0.806563 13.5566 2.23625 10.8027H2.23734L6.76797 11.6334L8.75266 16.1368C8.33727 17.3478 8.11086 18.6478 8.11086 20.0005C8.11102 21.4687 8.37695 22.8753 8.86484 24.173Z"
      fill="#FBBB00"
    />
    <path
      d="M39.6503 16.2637C39.88 17.4735 39.9998 18.723 39.9998 19.9999C39.9998 21.4318 39.8492 22.8285 39.5624 24.1758C38.5888 28.7604 36.0448 32.7637 32.5207 35.5966L32.5196 35.5956L26.813 35.3044L26.0054 30.2626C28.3438 28.8912 30.1713 26.745 31.134 24.1758H20.4395V16.2637H31.29H39.6503Z"
      fill="#518EF8"
    />
    <path
      d="M32.5191 35.5949L32.5202 35.596C29.0927 38.3509 24.7388 39.9993 19.9993 39.9993C12.3828 39.9993 5.76086 35.7422 2.38281 29.4773L8.86414 24.1719C10.5531 28.6795 14.9015 31.8884 19.9993 31.8884C22.1905 31.8884 24.2433 31.296 26.0048 30.262L32.5191 35.5949Z"
      fill="#28B446"
    />
    <path
      d="M32.766 4.60437L26.2869 9.90875C24.4638 8.76922 22.3088 8.11094 20.0001 8.11094C14.7869 8.11094 10.3572 11.467 8.75281 16.1362L2.23742 10.8022H2.23633C5.56492 4.38461 12.2704 0 20.0001 0C24.8528 0 29.3023 1.72859 32.766 4.60437Z"
      fill="#F14336"
    />
  </svg>
);

GoogleIcon.defaultProps = {
  width: 40,
  height: 40,
};

GoogleIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

export default GoogleIcon;
