import { takeLatest, call, put, delay } from 'redux-saga/effects';
import {push} from 'connected-react-router'
import AuthActions, { AuthTypes } from './reducer';
import ErrorsActions from '../../Redux/ErrorsRedux'
import * as services from './services';

export function* signIn(authApi, { email, password }) {
  try {
    const response = yield call(authApi.signIn, email, password);
    if (response && response.access && response.userInfo) {
      yield put(
        AuthActions.signInSuccess(
          response.userInfo,
          response.access,
          response.refresh,
        ),
      );
      yield delay(500);
      yield put(push('/dashboard'));

    } else if (response && response.data) {
      if (response.status === 401) {
        yield put(AuthActions.signInFailure(response.data));
      } else if (response.status === 500) {
        yield put(AuthActions.signInFailure('Server response error'));
        yield put(ErrorsActions.errorSave('Server response error'))
      } else {
        yield put(AuthActions.signInFailure(response.data));
        yield put(ErrorsActions.errorSave(response.data.error || response.data))
      }
    } else {
      // yield put(AuthActions.signInFailure('Server response error'));
      // yield put(ErrorsActions.errorSave('Server response error'))
      yield put(AuthActions.authFetchingReset());
    }
  } catch (e) {
    // yield put(AuthActions.signInFailure(`Unknown error: ${e}`));
    yield put(AuthActions.authFetchingReset());
    yield put(ErrorsActions.errorSave(e.data || e.originalError))
  }
}

export function* socialAuth(authApi, { accessToken, socialType, user }) {
  try {
    const response = yield call(authApi.socialAuth, accessToken, socialType, user);
    if (response?.access && response?.userInfo) {
      yield put(
        AuthActions.socialAuthSuccess(
          response.userInfo,
          response.access,
          response.refresh,
        ),
      );
      yield put(AuthActions.socialAuthReset());
      yield delay(500);
      yield put(push('/dashboard'));
    } else if (response?.data) {
      if (response.status === 401) {
        yield put(AuthActions.socialAuthFailure(null));
      } else if (response.status === 500) {
        yield put(AuthActions.socialAuthFailure('Server response error'));
      } else {
        yield put(AuthActions.socialAuthFailure(response.data));
      }
    } else {
      yield put(AuthActions.socialAuthFailure('Server response error'));
    }
  } catch (e) {
    yield put(AuthActions.socialAuthFailure(`Unknown error: ${e}`));
  }
}

export function* logout(authApi) {
  try {
    const response = yield call(authApi.logout);

    yield put(AuthActions.logoutSuccess())
    authApi.updateAccessToken(null);
    yield delay(500);
    yield put(push('/login'));
    //if(!response?.ok) yield put(ErrorsActions.errorSave(response?.data))
  } catch (e) {
    console.log('error:', e);
  }
}

const actions = {
  refreshTokenActions: {
    request: AuthActions.refreshTokenRequest,
    success: AuthActions.refreshTokenSuccess,
    errors: AuthActions.refreshTokenFailure,
  },
  verifyTokenActions: {
    request: AuthActions.verifyTokenRequest,
    success: AuthActions.verifyTokenSuccess,
    errors: AuthActions.verifyTokenFailure,
  },
};

const eventsOptions = {
  [AuthTypes.REFRESH_TOKEN_REQUEST]: {
    api: services.refreshToken,
    actions: actions.refreshTokenActions,
  },
  [AuthTypes.VERIFY_TOKEN_REQUEST]: {
    api: services.verifyToken,
    actions: actions.verifyTokenActions,
  },
};

// REFRESH TOKEN
function* refreshTokenGenerator(action) {
  const provider = eventsOptions[action.type];
  const params = action.payload;
  const response = yield call(provider.api, params);

  if (response.ok) {

    yield put(provider.actions.success(response?.data));

    // reload page after first refresh token
    window.location.reload();
  } else {
    yield put(provider.actions.errors({ errors: response?.data?.message?.detail }));

    // logout
    yield put(AuthActions.logout());
  }
}

// VERIFY TOKEN
function* verifyTokenGenerator(action) {
  const provider = eventsOptions[action.type];

  try {
    const params = action.payload;
    const response = yield call(provider.api, params);

    if (response.status === 200) {
      yield put(provider.actions.success(response.data));
    }

    if (response.status === 401 || response.status === 403) {
      yield put(provider.actions.errors({ errors: 'error 401 || 403' }));

      throw response;
    }

    if (response.status === 404) {
      throw response;
    }
  } catch (errors) {
    yield put(provider.actions.errors({ errors }));

    // refresh token
    //yield put(actions.refreshTokenActions.request(action.payload));
  }
}

export function* apiAuthSaga() {
  yield takeLatest(AuthTypes.REFRESH_TOKEN_REQUEST, refreshTokenGenerator);
  yield takeLatest(AuthTypes.VERIFY_TOKEN_REQUEST, verifyTokenGenerator);
}
