import React from 'react'
import PropTypes from 'prop-types'
import cls from 'classname'

export const ThreeDots = ({width, height, className, fill}) => (
  <svg width={width}
       height={height}
       viewBox="0 0 20 20"
       fill="none"
       className={cls(className, 'icon icon-three-dots')}
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0001 10.834C10.4603 10.834 10.8334 10.4609 10.8334 10.0007C10.8334 9.54041 10.4603 9.16732 10.0001 9.16732C9.53984 9.16732 9.16675 9.54041 9.16675 10.0007C9.16675 10.4609 9.53984 10.834 10.0001 10.834Z"
      stroke={fill} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M10.0001 5.00065C10.4603 5.00065 10.8334 4.62755 10.8334 4.16732C10.8334 3.70708 10.4603 3.33398 10.0001 3.33398C9.53984 3.33398 9.16675 3.70708 9.16675 4.16732C9.16675 4.62755 9.53984 5.00065 10.0001 5.00065Z"
      stroke={fill} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M10.0001 16.6673C10.4603 16.6673 10.8334 16.2942 10.8334 15.834C10.8334 15.3737 10.4603 15.0007 10.0001 15.0007C9.53984 15.0007 9.16675 15.3737 9.16675 15.834C9.16675 16.2942 9.53984 16.6673 10.0001 16.6673Z"
      stroke={fill} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

ThreeDots.defaultProps = {
  width: 20,
  height: 20,
  fill: '#667085',
}

ThreeDots.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
}

export default ThreeDots
