import React from 'react'

const ArrowSliderNext = ({size, className, style, ...props}) => (
  <svg width={size || '25'}
       height={size || '25'}
       fill="none"
       className={className}
       style={style} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5Z"
          fill={"#000000"}/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M9.01042 3.29289C9.40095 2.90237 10.0341 2.90237 10.4246 3.29289L17.435 10.3033C18.2161 11.0843 18.2161 12.3507 17.435 13.1317L10.4246 20.1421C10.0341 20.5326 9.40095 20.5326 9.01042 20.1421C8.6199 19.7516 8.6199 19.1184 9.01042 18.7279L16.0208 11.7175L9.01042 4.70711C8.6199 4.31658 8.6199 3.68342 9.01042 3.29289Z"
          fill={"#FFFFFF"}/>
  </svg>
)

export default ArrowSliderNext
