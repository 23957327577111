/**
 * Утилиты
 */
import { createBrowserHistory } from 'history';
import Images from '../Images';

export const history = createBrowserHistory();
export const signalsColors = {
  trust: '#50C07F',
  info: '#F2924C',
  risk: '#FC4163',
}

export const textColorsTrueFalse = {
  true: '#27AE60',
  false: '#FC4163',
}

export const serialize = (params) => {
  const search = new URLSearchParams();
  for (const [key, value] of Object.entries(params)) {
    search.append(key, value);
  }

  return search.toString();
};

export const scrollToTop = (id) => {
  const content = document.getElementById(id)
  if (content) {
    content.scrollTo({
      top: 0,
      // behavior: 'smooth',
    })
  }
}

export const parseJwt = (token) => {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export const debounce = (fn, delay) => {
  let timeOutId;
  return function (...args) {
    if (timeOutId) {
      clearTimeout(timeOutId);
    }
    timeOutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

export const openHideLink = (url) => {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  // link.setAttribute('download', 'fileName.pdf');
  // link.setAttribute('target', '_blank');
  link.setAttribute('target', '_self');
  // link.setAttribute('rel', 'noopener noreferrer');
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const AppUtils = {
  // Получить сообщение локализации
  getMes(context) {
    const ct = context.intl;
    return function res(mesId, values) {
      try {
        return ct.formatMessage(mesId, values);
      } catch (e) {
        return mesId;
      }
    };
  },

  // валидация и кориктеровка redirect uri
  controlRedirectUrl(redirectUrl, whitelist) {
    if (!redirectUrl) return '';
    // проверка по вайт листу, если он есть
    if (whitelist && whitelist.length > 0) {
      let isRedirect = false;
      whitelist.forEach((item) => {
        if (redirectUrl.indexOf(item) !== -1) {
          isRedirect = true;
        }
      });
      if (!isRedirect) return '';
    }

    return redirectUrl.substring(0, 4) === 'http' ? redirectUrl : '';
  },

  createRequestTypes(base) {
    const REQUEST = 'REQUEST';
    const SUCCESS = 'SUCCESS';
    const FAILURE = 'FAILURE';
    return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
      acc[type] = `${base}_${type}`;
      return acc;
    }, {});
  },

  // Перевести строку в CamelCase из underscore стиля
  toCamelCase(str, firstUpper) {
    const camelCase = str.replace(/_([a-z])/g, (m, w) => w.toUpperCase());
    if (!firstUpper) return camelCase;
    return camelCase.charAt(0).toUpperCase() + camelCase.slice(1);
  },

  toPascalCase(str) {
    if (!str) return;
    let arr = str.split('_');
    arr = arr.map((i) => this.toUpperLatter(i.toLocaleLowerCase()));
    return arr.join('');
  },

  toNormalText(str) {
    if (!str) return;
    let arr = str.split('_');
    arr = arr.map((x, i) => i === 0 ? this.toUpperLatter(x.toLocaleLowerCase()): x);
    return arr.join(' ');
  },

  // Перевести первую букву в верхний регистр
  toUpperLatter(str) {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return '';
  },

  toLowerLatter(str) {
    if (str) {
      return str.charAt(0).toLowerCase() + str.slice(1);
    }
    return '';
  },

  tryToBool(str) {
    if (str === 'false') return false;
    if (str === 'true') return true;
    return str;
  },

  random() {
    return Math.floor(Math.random() * 0xffff);
  },

  cutUrlToPrevious(str) {
    if (!str || typeof str !== 'string') return;
    const index = str.lastIndexOf('/');
    return str.slice(0, index);
  },

  formatValue(val) {
    if (val == null || isNaN(val)) {
      return;
    }
    val = Math.round(val);
    if (val === 100000) {
      val += '+';
    }
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },

  copyToClipboard(str) {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  },

  convertNumber(value, fractionDigits) {
    if (value >= 1000000000)
      return `${(value / 1000000000).toFixed(fractionDigits)}B`;
    if (value >= 1000000)
      return `${(value / 1000000).toFixed(fractionDigits)}M`;
    if (value >= 1000) return `${(value / 1000).toFixed(fractionDigits)}K`;
    return `${value}`;
  },

  goLink(link) {
    const a = document.createElement('a');
    a.href = link;
    a.click();
  },

  includeCurrentLocation(locations, pathname) {
    const splitStr = locations.split('/');
    return !!splitStr.includes(pathname);
  },

  addDefaultSrc(event) {
    event.target.src = Images.defaultImg;
  },

  randomNum(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  },

  range(start, end, step = 1) {
    const output = [];
    if (typeof end === 'undefined') {
      end = start;
      start = 0;
    }
    for (let i = start; i < end; i += step) {
      output.push(i);
    }
    return output;
  },

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  includeSearch(value1, value2) {
    return (
      value1.toLowerCase().indexOf(value2.replace(/\+/g, '').toLowerCase()) >= 0
    );
  },

  capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  },

  capitalizeFirstLetterEachWord(string) {
    return string?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  }
};

export default AppUtils;
