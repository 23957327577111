import React from 'react'
import PropTypes from 'prop-types'

export const Share = ({width, height}) =>
  <svg width={width}
       height={height} viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0256 8.42087C9.29001 8.42087 8.63144 8.75527 8.19448 9.27945L5.35202 7.67128C5.44041 7.42341 5.48899 7.1569 5.48899 6.87915C5.48899 6.60146 5.44041 6.33494 5.35202 6.08707L8.19425 4.47851C8.63117 5.00286 9.28984 5.33737 10.0255 5.33737C11.3378 5.33737 12.4054 4.27314 12.4054 2.96496C12.4055 1.65717 11.3379 0.593262 10.0256 0.593262C8.71323 0.593262 7.64552 1.65717 7.64552 2.9649C7.64552 3.24265 7.69404 3.50922 7.78249 3.75715L4.94009 5.36576C4.50318 4.84169 3.84467 4.5074 3.10921 4.5074C1.79664 4.5074 0.72876 5.5713 0.72876 6.87909C0.72876 8.18683 1.79664 9.25073 3.10921 9.25073C3.84467 9.25073 4.50312 8.91644 4.94003 8.39243L7.78249 10.0007C7.69404 10.2486 7.64546 10.5152 7.64546 10.793C7.64546 12.1007 8.71317 13.1646 10.0256 13.1646C11.3378 13.1646 12.4054 12.1007 12.4054 10.793C12.4055 9.48505 11.3379 8.42087 10.0256 8.42087ZM10.0256 1.42318C10.8786 1.42318 11.5726 2.11479 11.5726 2.9649C11.5726 3.81546 10.8786 4.5074 10.0256 4.5074C9.17246 4.5074 8.47837 3.81546 8.47837 2.9649C8.47837 2.11479 9.17246 1.42318 10.0256 1.42318ZM3.10927 8.42087C2.25593 8.42087 1.56166 7.72921 1.56166 6.87915C1.56166 6.02898 2.25593 5.33737 3.10927 5.33737C3.96227 5.33737 4.6562 6.02898 4.6562 6.87915C4.6562 7.72921 3.96221 8.42087 3.10927 8.42087ZM10.0256 12.3348C9.17246 12.3348 8.47837 11.6431 8.47837 10.7931C8.47837 9.94267 9.17246 9.25079 10.0256 9.25079C10.8786 9.25079 11.5726 9.94267 11.5726 10.7931C11.5726 11.6431 10.8786 12.3348 10.0256 12.3348Z"
      fill={"#34315A"}/>
  </svg>

Share.defaultProps = {
  width: 13,
  height: 14,
}

Share.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default Share
