import React from 'react'
import PropTypes from 'prop-types'

export const Bell = ({width, height, color}) =>

  <svg width={width} height={height} viewBox="0 0 17 16" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.0059 9.67483L10.8721 9.70829C10.3703 9.87556 9.835 9.64138 9.63428 9.13956L8.53028 6.19556C7.66046 3.85374 5.01754 2.71628 2.70918 3.68646C0.534632 4.58974 -0.435551 7.13228 0.367359 9.34029L1.40445 12.1839C1.57172 12.6857 1.30409 13.221 0.835723 13.3883L0.701905 13.4217C0.133177 13.6225 -0.13446 14.2916 0.0662679 14.8603L0.200086 15.2283C0.266995 15.3956 0.501177 15.5294 0.701905 15.429L12.2103 11.1803C12.411 11.1134 12.5114 10.8457 12.4445 10.645L12.3106 10.277C12.2103 9.74174 11.5746 9.44065 11.0059 9.67483ZM8.19573 13.8901L5.68664 14.8268C5.51936 14.8937 5.45245 15.0945 5.55282 15.2283C5.95427 15.8974 6.79064 16.165 7.52664 15.8974C8.26264 15.6297 8.69755 14.8603 8.59719 14.1243C8.53028 13.957 8.32955 13.8567 8.19573 13.8901Z"
    />
    <path
      d="M15.8567 8.87195C16.4254 6.96504 16.1243 4.92431 15.0872 3.25158C14.0501 1.54539 12.3439 0.374484 10.4036 0.00648399C10.2363 -0.0269706 10.1025 0.0733932 10.1025 0.207211L9.93519 1.14394C9.90174 1.27776 10.0021 1.37812 10.1359 1.41158C11.6414 1.71267 12.9796 2.6494 13.8159 3.98758C14.6523 5.32576 14.853 6.93158 14.4516 8.43704C14.4181 8.57086 14.485 8.70467 14.6188 8.73813L15.5221 9.03922C15.6894 9.03922 15.8232 9.00576 15.8567 8.87195Z"
    />
    <path
      d="M12.1769 4.99122C11.6416 4.1214 10.7718 3.55267 9.76815 3.35194C9.63434 3.31849 9.50052 3.41885 9.46706 3.55267L9.3667 4.52285C9.3667 4.65667 9.43361 4.75704 9.56743 4.79049C10.1362 4.92431 10.638 5.25885 10.9391 5.76067C11.2402 6.26249 11.3405 6.86467 11.1732 7.39995C11.1398 7.50031 11.2067 7.63413 11.3071 7.66758L12.2103 8.03558C12.3442 8.10249 12.478 8.00213 12.5449 7.86831C12.846 6.89813 12.7122 5.86104 12.1769 4.99122Z"
    />
  </svg>

Bell.defaultProps = {
  width: 17,
  height: 16,
  color: '#E10252'
}

Bell.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
}

export default Bell
