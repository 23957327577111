import ResendEmailLoader from './ResendEmailLoader'
import Eye from './Eye'
import AttentionOutline from './AttentionOutline'
import EyeInvisible from './EyeInvisible'

import ArrowLink from './ArrowLink'
import Close from './Close'
import NotificationSuccess from './NotificationSuccess'
import NotificationClose from './NotificationClose'
import ArrowLeft from './ArrowLeft'
import ArrowRight from './ArrowRight'
import ArrowSliderPrev from './ArrowSliderPrev'
import ArrowSliderNext from './ArrowSliderNext'
import Help from './Help'
import Warning from './Warning'
import CheckMark from './CheckMark'
import CloseModal from './CloseModal'

import ArrowSelectDown from './ArrowSelectDown'
import Notifications from './notifications'
import User from './user'

import Mail from './mail'

import Logout from './logout'
import EmptyWarning from './empty-warning'
import Menu from './menu'
import SelectDropdownCheckMark from './SelectDropdownCheckMark'
import SelectDropdownCheckMarkV2 from './SelectDropdownCheckMark_v2'

/*sidebar*/
import Home from './home'
import Report from './report'
import Dashboard from './dashboard'

import Settings from './settings'

import ChevronLeft from './chevron-left'

import Ellipse from './Ellipse'
import ArrowDownward from './ArrowDownward'

import SearchIcon from './SearchIcon'
import Edit from './Edit'
import GlobeOutline from './GlobeOutline'
import SettingIcon from './SettingIcon'
import NotificationWarning from './NotificationWarning'
import AppleIcon from './AppleIcon'
import FbIcon from './FbIcon'
import GoogleIcon from './GoogleIcon'
import ThreeDots from './ThreeDots'
import NotificationError from './NotificationError'
import NotificationDelete from './NotificationDelete'
import Download from './Download'
import ArrowUp from './ArrowUp'
import Lock from './Lock'

import Favorite from './Favorite'
import Share from './Share'

import ChevronUp from './ChevronUp'

import Twitter from './Twitter'
import Facebook from './Facebook'
import Instagram from './Instagram'

import Bell from './Bell'
import Filters from './Filters'

import Select from './Select'
import Sort from './Sort'

/*table*/
import Descend from './Descend'

import Calendar from './Calendar'
import Getintouch from './Getintouch'
import ArrowDownDf from './ArrowDownDf'
import ArrowDownDfSelect from './ArrowDownDfSelect'

export const svg = {
  ResendEmailLoader,
  Eye,
  AttentionOutline,
  EyeInvisible,

  ArrowLink,
  Close,
  NotificationSuccess,
  NotificationClose,
  ArrowLeft,
  ArrowRight,
  ArrowSliderPrev,
  ArrowSliderNext,
  Help,
  Warning,
  CheckMark,
  CloseModal,

  ArrowSelectDown,
  Notifications,
  User,
  Mail,

  Logout,
  EmptyWarning,
  Menu,
  SelectDropdownCheckMark,
  SelectDropdownCheckMarkV2,
  Home,
  Report,
  Dashboard,
  Settings,

  ChevronLeft,

  Ellipse,
  ArrowDownward,

  SearchIcon,
  Edit,
  GlobeOutline,
  SettingIcon,
  NotificationWarning,
  AppleIcon,
  FbIcon,
  GoogleIcon,
  ThreeDots,
  NotificationError,
  NotificationDelete,

  Download,
  ArrowUp,

  Lock,

  Favorite,
  Share,

  ChevronUp,

  Twitter,
  Facebook,
  Instagram,

  Bell,
  Filters,

  Select,
  Sort,

  Descend,
  Calendar,
  Getintouch,
  ArrowDownDf,
  ArrowDownDfSelect,

}
