import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

const ArrowDownDf = ({ width, height, className, outline, color }) => (
  <svg
    width="8" height="5" viewBox="0 0 8 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cls(className, 'icon icon-arrow-down-df')}
  >
    <path d="M1 1L4 3.99874L7 1" stroke="#0C0838" strokeLinejoin="round"/>
  </svg>
);

export default ArrowDownDf;
