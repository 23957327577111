import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

// REDUCER FUNCTIONS
import {
  verifyTokenFailure,
  verifyTokenSuccess,
  verifyTokenRequest,
  refreshTokenRequest,
  refreshTokenSuccess,
  refreshTokenFailure,
} from './reducerFunc';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  signInRequest: ['email', 'password'],
  signInSuccess: ['userInfo', 'accessToken', 'refreshToken'],
  signInFailure: ['error'],

  refreshTokenRequest: ['payload'], // payload: { email, token }
  refreshTokenSuccess: ['payload'], // payload: { data }
  refreshTokenFailure: ['payload'], // payload: { errors }

  verifyTokenRequest: ['payload'], // payload: { token }
  verifyTokenSuccess: ['payload'], // payload: { data }
  verifyTokenFailure: ['payload'], // payload: { errors }

  authErrorReset: null,

  updateTokens: ['accessToken', 'refreshToken'],

  logout: null,
  logoutSuccess: null,
  clearState: null,

  updateProfile: ['data'],

  socialAuthRequest: ['accessToken', 'socialType', 'user'],
  socialAuthSuccess: ['userInfo', 'accessToken', 'refreshToken'],
  socialAuthFailure: ['error'],

  socialAuthReset: null,

  authFetchingReset: null,
});

export const AuthTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  userInfo: null,
  accessToken: null,
  refreshToken: null,
  isValidRefreshToken: false,

  error: null,
  fetching: false,

  updateFetching: false,
  updateErrors: false,

  isAdmin: false,
  role: null,
});

/* ------------- Selectors ------------- */

export const AuthSelectors = {
  getUserInfo: (state) => state.auth.userInfo,
  getAccessToken: (state) => state.auth.accessToken,
  getRefreshToken: (state) => state.auth.refreshToken,
};

/* ------------- Reducers ------------- */

export const signInRequest = (state, { email, password }) =>
  state.merge({ fetching: true, error: null });

export const signInSuccess = (
  state,
  { userInfo, accessToken, refreshToken },
) => {
  const isAdmin = userInfo?.role?.id === 2 || userInfo?.role?.id === 3;
  const role = userInfo?.role?.id;
  return state.merge({
    fetching: false,
    error: null,
    userInfo,
    accessToken,
    refreshToken,
    isAdmin,
    role,
  });
};

export const signInFailure = (state, { error }) =>
  state.merge({ error, fetching: false });

export const authErrorReset = (state) =>
  state.merge({ error: null, fetching: false });

export const updateTokens = (state, { accessToken, refreshToken }) =>
  state.merge({ accessToken, refreshToken });

export const logout = (state) => state

export const logoutSuccess = (state) =>
  state.merge({ ...INITIAL_STATE });

export const clearState = (state) => state.merge({ ...INITIAL_STATE });

export const updateProfile = (state, { data }) => {
  let userInfo = {};
  if (data?.user) {
    userInfo = { ...state.userInfo, ...data.user };
  } else {
    userInfo = { ...state.userInfo, ...data };
  }

  return state.merge({ userInfo });
};

export const socialAuthRequest = (state, { accessToken, socialType, user }) =>
  state.merge({ fetching: true, error: null });

export const socialAuthFailure = (state, { error }) =>
  state.merge({ error, fetching: false });

export const socialAuthSuccess = (
  state,
  { userInfo, accessToken, refreshToken },
) => {
  const isAdmin = userInfo?.role?.id === 2 || userInfo?.role?.id === 3;
  const role = userInfo?.role?.id;
  return state.merge({
    fetching: false,
    error: null,
    userInfo,
    accessToken,
    refreshToken,
    isAdmin,
    role,
  });
};

export const authFetchingReset = (state) =>
  state.merge({
    fetching: false,
    updateFetching: false,
  });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SIGN_IN_REQUEST]: signInRequest,
  [Types.SIGN_IN_SUCCESS]: signInSuccess,
  [Types.SIGN_IN_FAILURE]: signInFailure,

  [Types.AUTH_ERROR_RESET]: authErrorReset,

  [Types.UPDATE_TOKENS]: updateTokens,

  [Types.LOGOUT]: logout,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
  [Types.CLEAR_STATE]: clearState,

  [Types.UPDATE_PROFILE]: updateProfile,

  [Types.SOCIAL_AUTH_REQUEST]: socialAuthRequest,
  [Types.SOCIAL_AUTH_SUCCESS]: socialAuthSuccess,
  [Types.SOCIAL_AUTH_FAILURE]: socialAuthFailure,


  [Types.AUTH_FETCHING_RESET]: authFetchingReset,

  [Types.REFRESH_TOKEN_REQUEST]: refreshTokenRequest,
  [Types.REFRESH_TOKEN_SUCCESS]: refreshTokenSuccess,
  [Types.REFRESH_TOKEN_FAILURE]: refreshTokenFailure,

  [Types.VERIFY_TOKEN_REQUEST]: verifyTokenRequest,
  [Types.VERIFY_TOKEN_SUCCESS]: verifyTokenSuccess,
  [Types.VERIFY_TOKEN_FAILURE]: verifyTokenFailure,
});
