import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  userRequest: null,
  userSuccess: ['payload'], // payload: { userData }
  userFailure: ['payload'], // payload: { errors }

  userErrorsReset: null,
});

export const UserTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  errors: false,
});

/* ------------- Selectors ------------- */
export const UserSelectors = {
  getState: (state) => state.user,
};

/* ------------- Reducers ------------- */

//GET USER DATA
export const userRequest = (state) =>
  state.merge({ fetching: true })
export const userSuccess = (state, { payload: userInfo }) =>
  state.merge({ fetching: false });
export const userFailure = (state, { payload: errors }) =>
  state.merge({ fetching: false, errors });

export const userErrorsReset = (state) => {
  return state.merge({ errors: false });
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_REQUEST]: userRequest,
  [Types.USER_SUCCESS]: userSuccess,
  [Types.USER_FAILURE]: userFailure,

  [Types.USER_ERRORS_RESET]: userErrorsReset,
});
