import React from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import UserOutlined from '@ant-design/icons/UserOutlined'
import {Avatar, Dropdown} from 'antd'

import actions from './actions'

import './styles.less'

const initItems = [
  {
    key: 1,
    title: 'Report',
    label: <Link to={'/report'}>Report</Link>,
  },
  {
    key: 2,
    title: 'Dashboard',
    label: <Link to={'/dashboard'}>Dashboard</Link>,
  },
  {
    key: 3,
    title: 'Settings',
    label: <Link to={'/settings'}>Settings</Link>,
  },
  {
    type: 'divider',
  },
]

export const MenuComponent = ({user, logout}) => {
  let items = [
    ...initItems,
    {
      key: 8,
      title: 'Log out',
      label: <div className="user_logout" onClick={logout}>Log out</div>,
    }
  ]

  return (
    <div className="dropdown-wrap">
      <Dropdown
        //getPopupContainer={() => dropdown.current}
        overlayClassName={'custom-menu'}
        menu={{items}}
        trigger={['click']}
        placement="bottomLeft"
      >
        <span style={{cursor: 'pointer'}}>
         {/* <Icon type="menu" role="icon"/>*/}
          {
            user?.avatar
              ? <Avatar size={48} src={user?.avatar} />
              : <Avatar size={48} icon={<UserOutlined/>}/>
          }
        </span>
      </Dropdown>
    </div>
  )
}

MenuComponent.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    avatar: PropTypes.string,
  }),
  //nav: PropTypes.shape({theme: PropTypes.string}),
  //changeTheme: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  user: state.auth.userInfo,
  //nav: state.nav,
})

const mapDispatchToProps = (dispatch) => ({
  logout() {dispatch(actions.logout())},
  //changeTheme(theme) {dispatch(actions.changeTheme('theme', theme))},
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuComponent)
