import { takeLatest, takeEvery, put, call } from 'redux-saga/effects';

import * as services from './services';
import Creators, { StartTypes as constants } from './reducer';
import ErrorsActions from '../../Redux/ErrorsRedux'

const actions = {
  getReportActions: {
    request: Creators.getFilesRequest,
    success: Creators.getFilestSuccess,
  },
  sendFileActions: {
    request: Creators.sendFileRequest,
    success: Creators.sendFileSuccess,
  },
};

const eventsOptions = {
  [constants.GET_FILES_REQUEST]: {
    api: services.getFileList,
    actions: actions.getReportActions,
  },
  [constants.SEND_FILE_REQUEST]: {
    api: services.sendFile,
    actions: actions.sendFileActions,
  },
};

function* apiGenerator(action) {
  const provider = eventsOptions[action.type];
  try {
    const params = action.payload;
    const response = yield call(provider.api, params);

    if (response.ok && response.data) {
      yield put(provider.actions.success(response.data));
    } else {
      yield put(Creators.startPageFetchingReset());
      // yield put(ErrorsActions.errorSave(response.data ))
    }
  } catch (errors) {
    yield put(Creators.startPageFetchingReset());
    // yield put(ErrorsActions.errorSave({ errors }));
  }
}

export default function* apiSaga() {
  yield takeLatest(constants.GET_FILES_REQUEST, apiGenerator);
  yield takeLatest(constants.SEND_FILE_REQUEST, apiGenerator);

}
