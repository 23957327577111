import {createReducer, createActions} from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  getFilesRequest: ['payload'],
  getFilesSuccess: ['payload'],

  sendFileRequest: ['payload'],
  sendFileSuccess: ['payload'],

  startPageFetchingReset: null,
  resetResults: null,
  logoutSuccess: null,
})

export const StartTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fileList: null,
  fetching: false,
  fileSendResult: null,
  getFileResult: null,
  // lastRefreshed: null,

})

/* ------------- Functions for reducer cases ------------- */

const getFilesRequest = (state, {payload}) => {
  return state.merge({fetching: true})
}
const getFilesSuccess = (state, {payload: data}) => {
  return state.merge({ fetching: false, getFileResult: data })
}

const sendFileRequest = (state, {payload}) => {
  return state.merge({fetching: true})
}
const sendFileSuccess = (state, {payload: data}) => {
  return state.merge({ fetching: false, fileSendResult: data })
}


// const resetEventDetailsInfo = (state, {payload: data}) =>
//   state.merge({eventDetailsInfo: null, eventDetailsInfoRaw: null})

const resetResults = (state, {}) =>
  state.merge({
    fileSendResult: null,
    getFileResult: null,
  })

const startPageFetchingReset = (state) =>
  state.merge({
    fetching: false,
  })

const logoutSuccess = (state) => INITIAL_STATE

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_FILES_REQUEST]: getFilesRequest,
  [Types.GET_FILES_SUCCESS]: getFilesSuccess,

  [Types.SEND_FILE_REQUEST]: sendFileRequest,
  [Types.SEND_FILE_SUCCESS]: sendFileSuccess,

  // [Types.RESET_EVENT_DETAILS_INFO]: resetEventDetailsInfo,

  [Types.RESET_RESULTS]: resetResults,
  [Types.START_PAGE_FETCHING_RESET]: startPageFetchingReset,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
})
