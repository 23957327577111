import React from 'react'
import PropTypes from 'prop-types'
import cls from 'classname'

export const Home = ({width, height, className, fill}) => (
  <svg
    width={width}
    height={height}
    fill="none"
    className={cls(className, 'icon icon-home')}
    viewBox="0 0 20 22"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 21V11H13V21M1 8L10 1L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8Z"
      stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

Home.defaultProps = {
  width: 20,
  height: 22,
  fill: '#D0D5DD',
}

Home.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
}

export default Home
