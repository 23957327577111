import logo from './logo.svg';
import logoWithText from './logoWithText.svg';

import successful from './logo.svg';
import successfulMobile from './logo.svg';

import checkoutSuccess from './logo.svg';
import checkoutSuccessMobile from './logo.svg';

import gmail from './to-gmail.svg';
import outlook from './toOutlook.svg';
import yahoo from './toYahoo.svg';
import protonmail from './toProtonmail.svg';
import aol from './toAol.svg';
import zoho from './toZoho.svg';
import icloud from './toICloud.svg';
import gmx from './toGMX.svg';
import hey from './toHey.svg';
import yandex from './toYandex.svg';
import google from './google.svg';
import facebook from './facebook.svg';
import apple from './apple.svg';
import email from './email.svg';
import lock from './lock.svg';

import edit from './edit.svg';
import check from './check.svg';

import ResendEmailLoader from './resend-email-loader.svg';

import house from './house.jpeg';
import bg from './bg.jpg';

import arrow_up from './arrow_up.svg'
import arrow_down from './arrow_down.svg'
import chart_up from './chart_up.svg'
import chart_down from './chart_down.svg'

//dataforest
// import getintouch from './getintouch.svg'
import search from './search.svg'
import star from './star.svg'
import slack from './Slack.jpg'
import users from './users.svg'
import industries from './industries.svg'
import market from './market.svg'
import example1 from './example1.jpg'
import example2 from './example2.jpg'
import example3 from './example3.jpg'
import circle from './circle.svg'
import dataforest from './dataforest.svg'
import clutch from './clutch.svg'
import starblack from './starblack.svg'
import upwork from './upwork.svg'
import fb2 from './fb2.svg'
import linked from './linked.svg'
import user from './company-page/user.svg'
import calendar from './company-page/calendar.svg'
import location from './company-page/location.svg'
import twitter from './company-page/twitter.svg'
import linked2 from './company-page/linked2.svg'
import checkmark from './company-page/checkmark.svg'

import banner from './catalog-page/banner.svg'
import banner1 from './catalog-page/banner_1.svg'
import banner2 from './catalog-page/banner_2.svg'
import banner3 from './catalog-page/banner_3.svg'
import banner4 from './catalog-page/banner_4.svg'
import image_top_modal from './catalog-page/image_top_modal.svg'
import quotation_mark from './catalog-page/quotation_mark.svg'

const Images = {
  logo,
  logoWithText,

  successful,
  successfulMobile,

  checkoutSuccess,
  checkoutSuccessMobile,

  gmail,
  outlook,
  yahoo,
  protonmail,
  aol,
  zoho,
  icloud,
  gmx,
  hey,
  yandex,
  google,
  facebook,
  email,
  lock,
  apple,

  edit,
  check,

  ResendEmailLoader,

  house,
  bg,

  arrow_up,
  arrow_down,
  chart_up,
  chart_down,

  //dataforest
  // getintouch,
  search,
  slack,
  star,
  users,
  industries,
  market,
  example1,
  example2,
  example3,
  circle,
  dataforest,
  clutch,
  starblack,
  upwork,

  linked,
  fb2,
  banner,
  banner1,
  banner2,
  banner3,
  banner4,

  //company-page
  user,
  calendar,
  location,
  twitter,
  linked2,
  checkmark,

  image_top_modal,
  quotation_mark,
};

export default Images;
