import React from 'react'
import PropTypes from 'prop-types'
import cls from 'classname'

export const ChevronUp = ({width, height, className, fill}) => (
  <svg width={width}
       height={height} viewBox="0 0 20 20"
       fill="none"
       className={cls('icon icon-chevron_up', className)}
       xmlns="http://www.w3.org/2000/svg">
    <path d="M5 12.5L10 7.5L15 12.5" stroke={fill} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

ChevronUp.defaultProps = {
  width: 20,
  height: 20,
  fill: '#667085',
}

ChevronUp.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
}

export default ChevronUp
