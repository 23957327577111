import { takeLatest, put, call } from 'redux-saga/effects';
import AuthActions from '../LoginPage/reducer';

import * as services from './services';
import UserActions, { UserTypes as constants } from './reducer';

const actions = {
  getUserInfoActions: {
    request: UserActions.userRequest,
    success: UserActions.userSuccess,
    errors: UserActions.userFailure,
  },
};

const eventsOptions = {
  [constants.USER_REQUEST]: {
    api: services.getUserInfo,
    actions: actions.getUserInfoActions,
  },
};

// const events = Object.keys(eventsOptions);

function* apiGenerator(action) {
  const provider = eventsOptions[action.type];

  try {
    const params = action.payload;
    const response = yield call(provider.api, params);
    if (response.data) {
      if (response.data?.message && response.ok) {
        yield put(provider.actions.success(response?.data?.message));
      } else if (response.data?.message && !response.ok) {
        yield put(provider.actions.errors({ errors: response.data?.message }));
      } else if (response.data && !response.ok) {
        const error = Object.values(response.data).length
          ? Object.values(response.data)[0]
          : null;
        yield put(provider.actions.errors({ errors: error }));
      } else {
        yield put(provider.actions.success(response.data));
        yield put(AuthActions.updateProfile(response.data));
      }
    } else {
      yield put(provider.actions.errors({ errors: 'some error' }));
    }
  } catch (errors) {
    yield put(provider.actions.errors({ errors }));
  }
}

export default function* apiSaga() {
  yield takeLatest(constants.USER_REQUEST, apiGenerator);
}
