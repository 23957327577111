import { takeLatest, all, call } from 'redux-saga/effects';
import AuthAPI from './Services/AuthApi';

// sagas
import UserSagas from './Containers/User/saga';
import DashboardSagas from './Containers/DashboardPage/saga';
import ReportSagas from './Containers/ReportPage/saga';
import StartSagas from './Containers/StartPage/saga';
import TableSagas from './Containers/TablePage/saga';
import CatalogSagas from './Containers/CatalogPage/saga';

/* ------------- Types ------------- */
import { StartupTypes } from './Redux/StartupRedux';
import { AuthTypes } from './Containers/LoginPage/reducer';

/* ------------- Sagas ------------- */

import { startup } from './Sagas/StartupSagas';

import {
  signIn,
  socialAuth,
  logout,
  apiAuthSaga,
} from './Containers/LoginPage/saga';

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const authApi = AuthAPI;

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    // some sagas only receive an action
    takeLatest(StartupTypes.STARTUP, startup),
    takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn, authApi),
    takeLatest(AuthTypes.SOCIAL_AUTH_REQUEST, socialAuth, authApi),
    takeLatest(AuthTypes.LOGOUT, logout, authApi),
    call(apiAuthSaga),
    call(UserSagas),
    call(DashboardSagas),
    call(ReportSagas),
    call(StartSagas),
    call(TableSagas),
    call(CatalogSagas),
  ]);
}
