import React, { useEffect } from 'react'
import { Modal, Typography, Flex, Button, Row, Col, Form, Input, App} from 'antd'
import { useForm } from '@formspree/react';

import Images from "../../../Images"
import './bannerModal.less'
import { colors } from '../../../colors'
import Icon from '../../../Icon';

const {Text, Title} = Typography

const BannerModal = ({ isOpen, setOpenModal, ...props}) => {
  const [form] = Form.useForm();
  const { message} = App.useApp();
  const [state, handleSubmit, reset] = useForm("xnqgldek");

  const handleFormSubmit = async (event) => {
    await handleSubmit(event);
  };

  const onClose = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    if (state.succeeded) {
      message.success('Thank you, your request successfully sent!', 2)
      form.resetFields();
      setOpenModal(false);
    }
  }, [state.succeeded]);

  return (
    <Modal
      getContainer={() => document?.getElementById('main-layout')}
      centered={true}
      zIndex={1001}
      open={isOpen}
      onCancel={onClose}
      closeIcon={true}
      destroyOnClose={true}
      width={1058}
      footer={false}
      className='banner-modal'
    >
      <Row style={{minHeight: 700}}>
        <Col span={12} className='left-part'>
          <Row>
            <Col span={16}>
              <Title style={{fontSize: 40, fontWeight: 300, marginTop: 0, marginBottom: 38}}>
                <span className='gradient'>We`d love to</span> hear from you
              </Title>
            </Col>
          </Row>

          <Form
            onFinish={handleFormSubmit}
            validateTrigger="onSubmit"
            className="modal-form"
            layout="vertical"
            form={form}
          >
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Form.Item
                    name="name"
                    label="Name"
                  >
                    <Input placeholder='' />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                    name="_replyto"
                    label="Email"
                  >
                    <Input placeholder='' type="email" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                    name="Phone"
                    label="Phone"
                  >
                    <Input placeholder='' />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                    name="Describe-project"
                    label="Describe your project"
                  >
                    <Input placeholder='' />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Button
                  className='dark-blue-button'
                  style={{width: '100%'}}
                  htmlType="submit"
                  disabled={state.submitting}
                >
                  <Row align={'middle'} justify={'space-between'}>
                    Let's make&nbsp;&nbsp;
                    <Icon type="getintouch" role="icon" />
                  </Row>
                </Button>
              </Col>
            </Row>
          </Form>

        </Col>
        <Col span={12} className='right-part'>
          <Flex vertical justify='space-between' style={{height: '100%'}}>
            <img src={Images.image_top_modal} alt=''  width={308}/>
            <Flex vertical>
              <img src={Images.quotation_mark} alt='' width={22}/>
              <Text style={{fontSize: 18, color: colors.white, padding: '26px 0'}}>
                "They have the best data engineering expertise we have seen on the market in recent years"
              </Text>
              <Text style={{fontSize: 14, fontWeight: 600, color: colors.white }}>Elias Nichupienko</Text>
              <Text style={{fontSize: 12, color: colors.gray3}} type='secondary'>CEO, Advascale</Text>
              <Row style={{marginTop: 43}} gutter={30}>
                <Col span={10}>
                  <Text className='gradient' style={{fontSize: 30, display: 'block'}}>210&nbsp;+</Text>
                  <Text style={{fontSize: 13, color: colors.white }}>Completed projects</Text>
                </Col>
                <Col span={14}>
                  <Text className='gradient' style={{fontSize: 30, display: 'block'}}>70&nbsp;+</Text>
                  <Text style={{fontSize: 13, color: colors.white }}>In-house employees</Text>
                </Col>
              </Row>
            </Flex>
          </Flex>
        </Col>
      </Row>
    </Modal>
  )
}

export default BannerModal