import React from 'react'
import PropTypes from 'prop-types'
import cls from 'classname'

const Descend = ({width, height, className, color}) => (
  <svg width={width}
       height={height}
       className={cls(className, 'icon')}
       fill="none"
       viewBox="0 0 11 11"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M5.66667 1V10.3333M5.66667 10.3333L10.3333 5.66667M5.66667 10.3333L1 5.66667"
          stroke={color}
          strokeWidth="1.33333"
          strokeLinecap="round" strokeLinejoin="round"
    />
  </svg>
)

Descend.defaultProps = {
  width: 11,
  height: 11,
  outline: 0,
  color: '#667085',
}

Descend.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  outline: PropTypes.number,
  color: PropTypes.string,
  // fill: PropTypes.string,
}

export default Descend
