import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

const ArrowDownDfSelect = ({ width, height, className, outline, color }) => (
  <svg
    width="14" height="8" viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cls(className, 'icon icon-arrow-down-df')}
  >
    <path d="M1 1L7 7L13 1" stroke="#0C0838" strokeLinecap="round"/>
  </svg>
);

export default ArrowDownDfSelect;
