import React from 'react'
import {Button, Row, Layout, Dropdown, Space, Typography} from 'antd'
import Images from '../../Images'
import { DownOutlined } from '@ant-design/icons';
import { openHideLink } from '../../Utils/utils';
import Icon from '../../Icon'

import './styles.less'

const {Header} = Layout
const {Text} = Typography

const itemsServices = [
  {
    label: (
      <a href="https://dataforest.ai/services/data-scraping">
        Data Scraping
      </a>
    ),
    key: '0',
  },
  {
    label: <a href="https://dataforest.ai/services/data-science">Data Science</a>,
    key: '1',
  },
  {
    label: <a href="https://dataforest.ai/services/data-integration">Data Integration</a>,
    key: '2',
  },
  {
    label: <a href="https://dataforest.ai/services/web-applications">Web Applications</a>,
    key: '3',
  },
  {
    label: <a href="https://dataforest.ai/services/devops-as-a-service">DevOps</a>,
    key: '4',
  },
];

const itemsSolutions = [
  {
    label: (
      <a href="https://dataforest.ai/cases">
        Data insights & Forecasting
      </a>
    ),
    key: '0',
  },
  {
    label: <a href="https://dataforest.ai/cases">Business process automation</a>,
    key: '1',
  },
  {
    label: <a href="https://dataforest.ai/cases">Marketing automation</a>,
    key: '2',
  },
  {
    label: <a href="https://dataforest.ai/cases">Sales automation</a>,
    key: '3',
  },
  {
    label: <a href="https://dataforest.ai/cases">Sales automation</a>,
    key: '4',
  },
  {
    label: <a href="https://dataforest.ai/cases">Supply chain automation</a>,
    key: '5',
  },
  {
    label: <a href="https://dataforest.ai/cases">Security automation</a>,
    key: '6',
  },
];
const itemsIndustries = [
  {
    label: (
      <a href="https://dataforest.ai/industry/ecommerce">
       E-commerce
      </a>
    ),
    key: '0',
  },
  {
    label: <a href="https://dataforest.ai/industry/ecommerce">Retail</a>,
    key: '1',
  },
  {
    label: <a href="https://dataforest.ai/industry/travel-tech">TravelTech</a>,
    key: '2',
  },
  {
    label: <a href="https://dataforest.ai/cases">Finance</a>,
    key: '3',
  },
  {
    label: <a href="https://dataforest.ai/cases">Cyber Security</a>,
    key: '4',
  },
  {
    label: <a href="https://dataforest.ai/cases">Marketing</a>,
    key: '5',
  },
  {
    label: <a href="https://dataforest.ai/cases">Real estate</a>,
    key: '6',
  },
  {
    label: <a href="https://dataforest.ai/cases">Pharma</a>,
    key: '7',
  },
  {
    label: <a href="https://dataforest.ai/cases">Insurance</a>,
    key: '8',
  },
];


const menuItem = (title, items) => {
  return (
    <Dropdown
      menu={{items}}
      // trigger={['hover']}
      // open={true}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space className='menu-item'>
          {title}
          {/* <DownOutlined /> */}
          <Icon type='arrow_down_df' role="icon" />
        </Space>
      </a>
    </Dropdown>
  )
}

const linkItem = (title, link) => (
    <Text className='link-item' onClick={()=> openHideLink(link)}>
      {title}
    </Text>
  )



const HeaderComponent = () => {
  return (
    <Header className={"header-custom"}
            // ref={headerRef}
    >
      <img src={Images.logoWithText} alt="" />

      <div className="header-middle-part">
        {menuItem('Services', itemsServices)}
        {menuItem('Solutions', itemsSolutions)}
        {menuItem('Industries', itemsIndustries)}
        {linkItem('Success stories', 'https://dataforest.ai/cases')}
        {linkItem('About us', 'https://dataforest.ai/about-us')}
        {linkItem('Join the team', 'https://dataforest.hurma.work/public-vacancies')}
        {linkItem('Blog', 'https://dataforest.ai/blog')}
      </div>

      <div className="header-right-part">
        <Button className='dark-blue-button' onClick={()=> openHideLink('https://dataforest.ai/#form-section')}>
          <Row align={'middle'}>
            Get in touch&nbsp;&nbsp;
            <Icon type="getintouch" role="icon" />
          </Row>
        </Button>
      </div>
    </Header>
  )
}

export default HeaderComponent