import React from 'react'
import PropTypes from 'prop-types'
import cls from 'classname'

export const Menu = ({width, height, className}) => (
  <svg width={width}
       height={height}
       viewBox="0 0 38 30"
       className={cls(className, 'icon icon-menu')}
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd"
          d="M0 3.19672C0 1.43122 1.43122 0 3.19672 0H34.6721C36.4376 0 37.8689 1.43122 37.8689 3.19672C37.8689 4.96222 36.4376 6.39344 34.6721 6.39344H3.19672C1.43121 6.39344 0 4.96222 0 3.19672ZM0 15C0 13.2345 1.43122 11.8033 3.19672 11.8033H34.6721C36.4376 11.8033 37.8689 13.2345 37.8689 15C37.8689 16.7655 36.4376 18.1967 34.6721 18.1967H3.19672C1.43121 18.1967 0 16.7655 0 15ZM3.19672 23.6066C1.43122 23.6066 0 25.0378 0 26.8033C0 28.5688 1.43121 30 3.19672 30H34.6721C36.4376 30 37.8689 28.5688 37.8689 26.8033C37.8689 25.0378 36.4376 23.6066 34.6721 23.6066H3.19672Z"
          fill={"#34315A"}
    />
  </svg>
)

Menu.defaultProps = {
  width: 38,
  height: 30,
}

Menu.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
}

export default Menu
