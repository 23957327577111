import React from 'react'
import cls from 'classname'
import {notification} from 'antd'

import NotificationSuccess from '../../Icon/img/NotificationSuccess'
import NotificationWarning from '../../Icon/img/NotificationWarning'
import NotificationClose from '../../Icon/img/NotificationClose'
import NotificationError from '../../Icon/img/NotificationError'
import NotificationDelete from '../../Icon/img/NotificationDelete'

import './styles.less'

const notificationsTypes = {
  success: <NotificationSuccess/>,
  warning: <NotificationWarning/>,
  info: <NotificationSuccess/>,
  error: <NotificationError/>,
  delete: <NotificationDelete/>,
}

const openNotification = (
  {
    type,
    message,
    style,
    getContainer,
    className,
    ...props
  }) => {
  notification['success']({
    message,
    duration: 3,
    top: 96,
    onClose: () => notification.destroy(),
    icon: notificationsTypes[type] || notificationsTypes?.success,
    closeIcon: <NotificationClose/>,
    className: cls('custom-notification', className),
    getContainer: () => getContainer || document.getElementById('global-wrap'),
    style: {...style},
    ...props,
  })
}

export default openNotification
