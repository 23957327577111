import React from 'react'
import PropTypes from 'prop-types'

export const Sort = ({width, height, color}) =>
  <svg width={width} height={height} viewBox="0 0 17 17" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.4999 11.5908H5.40901V0.772732C5.40904 0.345975 5.063 0 4.63631 0C4.20952 0 3.86357 0.345975 3.86357 0.772732V11.5908H0.772713C0.46024 11.5908 0.17838 11.7791 0.0588162 12.0678C-0.0607476 12.3566 0.00535946 12.689 0.226358 12.91L4.08992 16.7736C4.23482 16.9185 4.43135 16.9999 4.63634 16.9999C4.84127 16.9999 5.0378 16.9184 5.18266 16.7735L9.04626 12.9099C9.26732 12.6889 9.3334 12.3566 9.2138 12.0678C9.0942 11.7791 8.81241 11.5908 8.4999 11.5908Z"
    />
    <path
      d="M16.7736 4.08996L12.91 0.226336C12.6083 -0.0754454 12.119 -0.0754454 11.8172 0.226336L7.95361 4.08996C7.73268 4.31096 7.66657 4.64332 7.78607 4.93209C7.9057 5.22082 8.18746 5.40912 8.5 5.40912H11.5909V16.2273C11.5909 16.654 11.9368 17 12.3636 17C12.7903 17 13.1364 16.654 13.1364 16.2273V5.40908H16.2273C16.5397 5.40908 16.8215 5.22079 16.9411 4.93206C17.0607 4.64326 16.9946 4.31096 16.7736 4.08996Z"
    />
  </svg>

Sort.defaultProps = {
  width: 17,
  height: 17,
  color: '#000000'
}

Sort.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
}

export default Sort
