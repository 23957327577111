import { put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import StartupActions from '../Redux/StartupRedux';
import { AuthSelectors } from '../Containers/LoginPage/reducer';
import AuthApi from '../Services/AuthApi';

import utils from '../Utils/utils';

const registrationPages = [
  'login',
  'start',
  'table',
  'catalog',
];
const innerPages = [
  'dashboard',
  'report',
];

// process STARTUP actions
export function* startup() {
  const userInfo = yield select(AuthSelectors.getUserInfo);
  const accessToken = yield select(AuthSelectors.getAccessToken);
  const refreshToken = yield select(AuthSelectors.getRefreshToken);
  const { pathname, search } = document.location;

  if(accessToken) AuthApi.updateAccessToken(accessToken, refreshToken);
  if (userInfo) {
    if (
      pathname !== '/' &&
      innerPages.some((el) => utils.includeCurrentLocation(pathname, el))
    ) {
      yield put(push(search ? pathname + search : pathname));
    } else {
      yield put(push('/dashboard'));
    }
  } else if (
    registrationPages.some((el) => utils.includeCurrentLocation(pathname, el))
  ) {
    console.log('startup saga 1')
    yield put(push(pathname));
  } else {
    console.log('startup saga 2')
    // yield put(push('/login'));
    yield put(push('/catalog'));
  }

  yield put(StartupActions.startupSuccess());
}
