import React, {useEffect, useRef} from 'react'
import {connect} from 'react-redux'
import {useLocation} from 'react-router-dom'
import PropTypes from 'prop-types'
import cls from 'classname'
import {Layout, Grid, Typography, Button, Row, Select, message} from 'antd'

// actions
//import actions from './actions'
//import UserCreators from "../User/reducer"
//import AuthCreators from "../LoginPage/reducer"
import Sidebar from '../Sidebar'
import HeaderComponent from '../Header'
import MenuComponent from '../User'
import Creators from '../DashboardPage/reducer'
// import Images from '../../Images'

import './styles.less'

const {useBreakpoint} = Grid
const {Header, Content} = Layout
const {Title, Text} = Typography

const title = {
  '/report': 'Report',
  '/dashboard': 'Dashboard',
  '/settings': 'Settings',
}

export const LayoutComponent = (
  {
    nav,
    children,
    sidebar,
    // lastRefreshed,
    // apiKeyOptions,
    // apiKeySelected,
    userInfo,
    ...props
  }) => {
  const {theme} = nav
  const {pathname} = useLocation()
  const {xl} = useBreakpoint()

  // const headerRef = useRef()
  // const isHidden = useRef(false)
  // const isDashboard = pathname === '/dashboard'

  // useEffect(() => {
  //   let lastScroll = 0
  //   let defaultOffset = 60
  //   let scrollPosition = () => window?.scrollY || document?.documentElement?.scrollTop

  //   const handleShowHeader = () => {
  //     if (scrollPosition() > lastScroll && !isHidden.current && scrollPosition() > defaultOffset) {
  //       isHidden.current = true
  //       headerRef.current.style.top = '-140px'
  //     } else if (scrollPosition() < lastScroll && isHidden.current) {
  //       isHidden.current = false
  //       headerRef.current.style.top = 0
  //     }
  //     lastScroll = scrollPosition()
  //   }

  //   document.addEventListener('scroll', handleShowHeader)
  //   return () => {
  //     document.removeEventListener('scroll', handleShowHeader)
  //   }
  // }, [])

  // const handleChangeApiKeySelected = (value, option) => {
  //   props.changeApiKeySelected(value)
  //   // if (!value?.length) {
  //   //   message.error('Choose one API key', 3)
  //   // }
  // }

  return (
    <Layout
      hasSider={sidebar}
      id="global-wrap"
      className='global-wrap'
      // className={cls('global-wrap', {
      //   'theme-light': theme === 'light',
      //   'theme-dark': theme === 'dark',
      // })}
    >
      {sidebar && <Sidebar/>}

      <Layout className="site-layout">
        <HeaderComponent />
        {/* <Header className={"header"}
                // ref={headerRef}
        >
          <img src={Images.logoWithText} alt="" />

          <div className="header-middle-part">
            Menu
          </div>

          <div className="header-right-part">
            <Button className='header-button'>
              <Row>
                Get in touch&nbsp;&nbsp;
                <img src={Images.getintouch} alt="" />
              </Row>
            </Button>
          </div>

        </Header> */}

        <Content
          //className={`main-content ${pathname.slice(1).replace('/', '-')}`}
          className="main-content"
          id='scroll-container'
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}

LayoutComponent.propTypes = {
  nav: PropTypes.shape({
    isMobile: PropTypes.bool,
    theme: PropTypes.string,
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

const mapStateToProps = (state) => ({
  nav: state.nav,
  userInfo: state.auth.userInfo,
  // lastRefreshed: state.dashboard.lastRefreshed,
  // apiKeyOptionsFetching: state.dashboard.apiKeyOptionsFetching,
  // apiKeyOptions: state.dashboard.apiKeyOptions,
  // apiKeySelected: state.dashboard.apiKeySelected,
})

const mapDispatchToProps = (dispatch) => ({
  // changeApiKeySelected: (selectedArr) => dispatch(Creators.changeApiKeySelected(selectedArr)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LayoutComponent)
