import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { API } from '../Services/Api';

const withAuth = (WrappedComponent) => {
  class ComposedComponent extends Component {
    api = new API();
    authUrl = 'auth/token/refresh/';

    state = {
      isBlocked: false,
    };

    requestInterceptor = this.api.interceptors.request.use((req) => {
      const { auth } = this.props;

      req.headers.Authorization = auth?.accessToken ? `Bearer ${auth?.accessToken}` : null;

      if( [this.authUrl, 'auth/token/verify/'].includes(req.url) && req.headers.Authorization) delete req.headers.Authorization

      return req;
    });

    responseInterceptor = this.api.interceptors.response.use(
      (res) => {
        const { status, config } = res;
        const { url } = config;

        if(url === 'auth/token/verify/' && status === 200 && this.state.isBlocked) this.setState({ isBlocked: false });

        return res;
      },
      (err) => {
        const { refreshToken, auth, logout, userInfo, errorSave } = this.props;
        const { status } = err.response || { status: null };
        console.log('hoc err', err)
        const { url } = err.config;
        if (!status) {
          errorSave(err)
        }

        // если первый запрос на refresh token не прошел (выдал 401 или 403) выходим из приложения
        if (this.authUrl === url && this.state.isBlocked) {
          this.setState({ isBlocked: false });
          logout();
        }

        // если запросы заблокированы, то отменяем все последующие запросы на refresh;
        if (this.state.isBlocked) return Promise.reject(err);

        // блокируем запросы после первой ошибки
        this.setState({ isBlocked: true });

        if (status === 401 || (url ==='auth/token/verify/' && status === 400)) {
          // if (auth?.refreshToken) return refreshToken({token: auth.refreshToken, email: userInfo?.email});
          console.log('withAuth err', err)
          logout();
        }

        if (status >= 500) {
          return Promise.reject(err);
        }

        return Promise.reject(err);
      },
    );

    componentWillUnmount() {
      this.api.interceptors.request.eject(this.requestInterceptor);
      this.api.interceptors.response.eject(this.responseInterceptor);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  ComposedComponent.propTypes = {
    refreshToken: PropTypes.func,
    logout: PropTypes.func,
    auth: PropTypes.shape({
      accessToken: PropTypes.string,
      refreshToken: PropTypes.string,
    }),
  };

  return ComposedComponent;
};

export default withAuth;
