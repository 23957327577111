import { takeLatest, takeEvery, put, call } from 'redux-saga/effects';

import * as services from './services';
import Creators, { DashboardTypes as constants } from './reducer';
import ErrorsActions from '../../Redux/ErrorsRedux'

const actions = {
  getDashboardFiltersOptionsActions: {
    request: Creators.getDashboardFiltersOptionsRequest,
    success: Creators.getDashboardFiltersOptionsSuccess,
    // fetchingReset: Creators.dashboardFetchingReset,
  },
  getApiKeyOptionsActions: {
    request: Creators.getApiKeyOptionsRequest,
    success: Creators.getApiKeyOptionsSuccess,
    // fetchingReset: Creators.getApiKeyOptionsFailure,
  },
  getDashboardChartsDataActions: {
    request: Creators.getDashboardChartsDataRequest,
    success: Creators.getDashboardChartsDataSuccess,
    // fetchingReset: Creators.dashboardFetchingReset,
  },
  getDashboardChartsDataEventsActions: {
    request: Creators.getDashboardChartsDataEventsRequest,
    success: Creators.getDashboardChartsDataEventsSuccess,
    // fetchingReset: Creators.dashboardFetchingReset,
  },
  getDashboardChartsDataUsersActions: {
    request: Creators.getDashboardChartsDataUsersRequest,
    success: Creators.getDashboardChartsDataUsersSuccess,
    // fetchingReset: Creators.dashboardFetchingReset,
  },
  getDashboardChartsDataAlertsActions: {
    request: Creators.getDashboardChartsDataAlertsRequest,
    success: Creators.getDashboardChartsDataAlertsSuccess,
    // fetchingReset: Creators.dashboardFetchingReset,
  },
  getEventsTableColumnsActions: {
    request: Creators.getEventsTableColumnsRequest,
    success: Creators.getEventsTableColumnsSuccess,
    // fetchingReset: Creators.dashboardFetchingReset,
  },
  getDashboardInfoActions: {
    request: Creators.getDashboardInfoRequest,
    success: Creators.getDashboardInfoSuccess,
    // fetchingReset: Creators.dashboardFetchingReset,
  },
  getNextDashboardInfoActions: {
    request: Creators.getNextDashboardInfoRequest,
    success: Creators.getNextDashboardInfoSuccess,
    // fetchingReset: Creators.dashboardFetchingReset,
  },
  getEventDetailsInfoActions: {
    request: Creators.getEventDetailsInfoRequest,
    success: Creators.getEventDetailsInfoSuccess,
    // fetchingReset: Creators.dashboardFetchingReset,
  },
  getDashboardDateRefreshActions: {
    request: Creators.getDashboardDateRefreshRequest,
    success: Creators.getDashboardDateRefreshSuccess,
    // fetchingReset: Creators.dashboardFetchingReset,
  },
};

const eventsOptions = {
  [constants.GET_DASHBOARD_FILTERS_OPTIONS_REQUEST]: {
    api: services.getDashboardFiltersOptions,
    actions: actions.getDashboardFiltersOptionsActions,
  },
  [constants.GET_API_KEY_OPTIONS_REQUEST]: {
    api: services.getApiKeyOptions,
    actions: actions.getApiKeyOptionsActions,
  },
  [constants.GET_DASHBOARD_CHARTS_DATA_REQUEST]: {
    api: services.getDashboardCharts,
    actions: actions.getDashboardChartsDataActions,
  },
  [constants.GET_DASHBOARD_CHARTS_DATA_EVENTS_REQUEST]: {
    api: services.getDashboardCharts,
    actions: actions.getDashboardChartsDataEventsActions,
  },
  [constants.GET_DASHBOARD_CHARTS_DATA_USERS_REQUEST]: {
    api: services.getDashboardCharts,
    actions: actions.getDashboardChartsDataUsersActions,
  },
  [constants.GET_DASHBOARD_CHARTS_DATA_ALERTS_REQUEST]: {
    api: services.getDashboardCharts,
    actions: actions.getDashboardChartsDataAlertsActions,
  },
  [constants.GET_EVENTS_TABLE_COLUMNS_REQUEST]: {
    api: services.getDashboardEventsTableColumns,
    actions: actions.getEventsTableColumnsActions,
  },
  [constants.GET_DASHBOARD_INFO_REQUEST]: {
    api: services.getDashboardInfo,
    actions: actions.getDashboardInfoActions,
  },
  [constants.GET_NEXT_DASHBOARD_INFO_REQUEST]: {
    api: services.getNextDashboardInfo,
    actions: actions.getNextDashboardInfoActions,
  },
  [constants.GET_EVENT_DETAILS_INFO_REQUEST]: {
    api: services.getDashboardEventDetailsInfo,
    actions: actions.getEventDetailsInfoActions,
  },
  [constants.GET_DASHBOARD_DATE_REFRESH_REQUEST]: {
    api: services.getDashboardDateRefresh,
    actions: actions.getDashboardDateRefreshActions,
  },
};

function* apiGenerator(action) {
  const provider = eventsOptions[action.type];

  try {
    const params = action.payload;
    const response = yield call(provider.api, params);

    if (response.ok && response.data) {
      yield put(provider.actions.success(response.data));
    } else {
      yield put(Creators.dashboardFetchingReset());
      // if (action.type === 'GET_EVENT_DETAILS_INFO_REQUEST') return;
      // yield put(ErrorsActions.errorSave(response.data ))
    }
  } catch (errors) {
    yield put(Creators.dashboardFetchingReset());
    // yield put(ErrorsActions.errorSave({ errors }));
  }
}

export default function* apiSaga() {
  yield takeLatest(constants.GET_DASHBOARD_FILTERS_OPTIONS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_API_KEY_OPTIONS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_DASHBOARD_CHARTS_DATA_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_DASHBOARD_CHARTS_DATA_EVENTS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_DASHBOARD_CHARTS_DATA_USERS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_DASHBOARD_CHARTS_DATA_ALERTS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_DASHBOARD_INFO_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_DASHBOARD_DATE_REFRESH_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_NEXT_DASHBOARD_INFO_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_EVENTS_TABLE_COLUMNS_REQUEST, apiGenerator);
  yield takeEvery(constants.GET_EVENT_DETAILS_INFO_REQUEST, apiGenerator);
}
