import React from 'react'

const ArrowSliderPrev = ({size, className, style, ...props}) => (
  <svg width={size || '25'}
       height={size || '25'}
       className={className}
       style={style}
       viewBox="0 0 25 25"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M0 12.5C0 19.4036 5.59644 25 12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5Z"
          fill={"#000000"}/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M15.9896 3.29289C15.5991 2.90237 14.9659 2.90237 14.5754 3.29289L7.56497 10.3033C6.78392 11.0843 6.78392 12.3507 7.56497 13.1317L14.5754 20.1421C14.9659 20.5326 15.5991 20.5326 15.9896 20.1421C16.3801 19.7516 16.3801 19.1184 15.9896 18.7279L8.97919 11.7175L15.9896 4.70711C16.3801 4.31658 16.3801 3.68342 15.9896 3.29289Z"
          fill={"#FFFFFF"}/>
  </svg>
)

export default ArrowSliderPrev
