import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {useLocation} from 'react-router-dom'
import cls from 'classname'

import actions from './actions'
//import Icon from '../../Icon'

import {
  navIconsArr,
  navIconsAdmin,
  NavLinkWithIcon,
} from './navigation'

import './styles.less'
import Images from '../../Images'

export const SidebarComponent = ({nav, isAdmin, changeSizeSidebar}) => {
  const {isShowMenu, isShowSideBar, theme, isMobile} = nav
  const location = useLocation()

  const isActive = (path) => !location.pathname.includes(path)

  const menuIcons = isAdmin
    ? navIconsAdmin[isMobile ? 'mobile' : 'desktop']
    : navIconsArr[isMobile ? 'mobile' : 'desktop']

  return (
    <aside
      className={cls('main-sidebar', {
        'closed-sidebar': isMobile || isShowMenu,
        'opened-sidebar': !isShowMenu && !isMobile,
        'small-sidebar': isShowSideBar,
        'big-sidebar': !isShowSideBar && !isMobile,
      })}
    >
      <div className="main-sidebar_wrap">

        <div className='logo-wrapper'>
          <img src={Images.logo} alt=""/>
        </div>

        {menuIcons.map(({url, iconDesk, text, comingSoon, markText}) => (
          <NavLinkWithIcon
            key={url}
            url={url}
            theme={theme}
            text={text}
            active={isActive(url)}
            iconType={iconDesk}
            isOpened={isShowSideBar}
            isSoon={comingSoon}
            markText={markText || null}
          />
        ))}
      </div>
      <div className="main-sidebar_bottom">
        {/*<button
          type="button"
          onClick={() => changeSizeSidebar(!isShowSideBar)}
          className={cls('main-sidebar_reduce-size', {
            'small-sidebar': isShowSideBar,
          })}
        >
          <Icon
            className="sidebar-link_icon"
            type="chevron_left"
            role="icon"
          />
        </button>*/}

        <NavLinkWithIcon
          key={'url'}
          url={'/settings'}
          theme={theme}
          text={'text'}
          active={isActive('/settings')}
          iconType={'settings'}
          isOpened={isShowSideBar}
        />

      </div>
    </aside>
  )
}

SidebarComponent.propTypes = {
  nav: PropTypes.shape({
    isShowMenu: PropTypes.bool,
    isShowSideBar: PropTypes.bool,
    isMobile: PropTypes.bool,
    theme: PropTypes.string,
  }),
  changeSizeSidebar: PropTypes.func,
}

const mapStateToProps = (state) => ({
  nav: state.nav,
  isAdmin: state.auth.isAdmin,
})

const mapDispatchToProps = (dispatch) => ({
  changeSizeSidebar(value) {
    dispatch(actions.changeSizeSidebar('isShowSideBar', value))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SidebarComponent)
