import React from 'react'
import PropTypes from 'prop-types'
import cls from 'classname'

export const Report = ({width, height, className, fill}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="0"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.74419 4.46348C5.20657 4.46348 5.5814 4.08865 5.5814 3.62627C5.5814 3.16389 5.20657 2.78906 4.74419 2.78906C4.28181 2.78906 3.90698 3.16389 3.90698 3.62627C3.90698 4.08865 4.28181 4.46348 4.74419 4.46348Z"
    fill={fill}
    />
    <path d="M7.53496 4.46348C7.99734 4.46348 8.37217 4.08865 8.37217 3.62627C8.37217 3.16389 7.99734 2.78906 7.53496 2.78906C7.07259 2.78906 6.69775 3.16389 6.69775 3.62627C6.69775 4.08865 7.07259 4.46348 7.53496 4.46348Z"
    // fill="#D0D5DD"
    fill={fill}
    />
    <path d="M10.3255 4.46348C10.7879 4.46348 11.1627 4.08865 11.1627 3.62627C11.1627 3.16389 10.7879 2.78906 10.3255 2.78906C9.86311 2.78906 9.48828 3.16389 9.48828 3.62627C9.48828 4.08865 9.86311 4.46348 10.3255 4.46348Z"
    // fill="#D0D5DD"
    fill={fill}
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M24 3.62791C24 1.62419 22.3758 0 20.3721 0C16.0833 0 7.91665 0 3.62791 0C1.62419 0 0 1.62419 0 3.62791V20.3721C0 22.3758 1.62419 24 3.62791 24H20.3721C22.3758 24 24 22.3758 24 20.3721V3.62791ZM22.3256 3.62791V20.3721C22.3256 21.4515 21.4515 22.3256 20.3721 22.3256H3.62791C2.54847 22.3256 1.67442 21.4515 1.67442 20.3721V3.62791C1.67442 2.54847 2.54847 1.67442 3.62791 1.67442H20.3721C21.4515 1.67442 22.3256 2.54847 22.3256 3.62791Z"
    // fill="#D0D5DD"
    fill={fill}
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M20.6512 19.2568V10.3265C20.6512 9.24821 19.7772 8.37305 18.6977 8.37305H5.30237C4.22292 8.37305 3.34888 9.24821 3.34888 10.3265V19.2568C3.34888 20.3351 4.22292 21.2103 5.30237 21.2103H18.6977C19.7772 21.2103 20.6512 20.3351 20.6512 19.2568ZM18.9768 10.3265V19.2568C18.9768 19.4108 18.8518 19.5358 18.6977 19.5358H5.30237C5.14832 19.5358 5.0233 19.4108 5.0233 19.2568V10.3265C5.0233 10.1725 5.14832 10.0475 5.30237 10.0475H18.6977C18.8518 10.0475 18.9768 10.1725 18.9768 10.3265Z"
    // fill="#D0D5DD"
    fill={fill}
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M23.1628 5.58203H0.837209C0.37507 5.58203 0 5.9571 0 6.41924C0 6.88138 0.37507 7.25645 0.837209 7.25645H23.1628C23.6249 7.25645 24 6.88138 24 6.41924C24 5.9571 23.6249 5.58203 23.1628 5.58203Z"
    // fill="#D0D5DD"
    fill={fill}
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.18609 17.5807H19.814C20.2761 17.5807 20.6512 17.2056 20.6512 16.7435C20.6512 16.2813 20.2761 15.9062 19.814 15.9062H4.18609C3.72395 15.9062 3.34888 16.2813 3.34888 16.7435C3.34888 17.2056 3.72395 17.5807 4.18609 17.5807Z"
    // fill="#D0D5DD"
    fill={fill}
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.9536 9.21026V20.373C13.9536 20.8352 14.3287 21.2103 14.7908 21.2103C15.253 21.2103 15.628 20.8352 15.628 20.373V9.21026C15.628 8.74812 15.253 8.37305 14.7908 8.37305C14.3287 8.37305 13.9536 8.74812 13.9536 9.21026Z"
    // fill="#D0D5DD"
    fill={fill}
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.18609 13.6744H19.814C20.2761 13.6744 20.6512 13.2993 20.6512 12.8372C20.6512 12.3751 20.2761 12 19.814 12H4.18609C3.72395 12 3.34888 12.3751 3.34888 12.8372C3.34888 13.2993 3.72395 13.6744 4.18609 13.6744Z"
    // fill="#D0D5DD"
    fill={fill}
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.37207 9.21026V20.373C8.37207 20.8352 8.74714 21.2103 9.20928 21.2103C9.67142 21.2103 10.0465 20.8352 10.0465 20.373V9.21026C10.0465 8.74812 9.67142 8.37305 9.20928 8.37305C8.74714 8.37305 8.37207 8.74812 8.37207 9.21026Z"
    // fill="#D0D5DD"
    fill={fill}
    />
  </svg>
)

Report.defaultProps = {
  width: 20,
  height: 22,
  fill: '#D0D5DD',
}

Report.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
}

export default Report
