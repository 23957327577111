import React from 'react'
import PropTypes from 'prop-types'
import cls from 'classname'

export const Dashboard = ({width, height, className, fill}) => (
  <svg width={width}
       height={height}
       fill="none"
       className={cls(className, 'icon icon-dashboard')}
       viewBox="0 0 14 18"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 17V7M7 17V1M1 17V11" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

Dashboard.defaultProps = {
  width: 14,
  height: 18,
  fill: '#D0D5DD',
}

Dashboard.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
}

export default Dashboard
