import React from 'react'
import PropTypes from 'prop-types'

export const Twitter = ({width, height, fill}) =>
  <svg width={width} height={height} viewBox="0 0 35 28" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33.4244 3.2314C32.8871 3.46969 32.3347 3.6708 31.7703 3.83408C32.4385 3.07838 32.948 2.1892 33.259 1.21617C33.3287 0.998069 33.2565 0.759336 33.0772 0.616691C32.8981 0.473937 32.6494 0.456837 32.4522 0.573667C31.2536 1.28457 29.9604 1.79547 28.6045 2.09421C27.2386 0.759557 25.3834 0 23.4656 0C19.4174 0 16.1239 3.2934 16.1239 7.3415C16.1239 7.66032 16.1441 7.97738 16.184 8.29003C11.1606 7.84897 6.4904 5.37989 3.28338 1.44597C3.16909 1.30575 2.99302 1.23018 2.81275 1.24464C2.63238 1.25876 2.47032 1.36047 2.37919 1.5168C1.72874 2.63291 1.38488 3.90943 1.38488 5.20823C1.38488 6.97722 2.01646 8.65563 3.13213 9.96712C2.7929 9.84963 2.4637 9.7028 2.14951 9.52838C1.98083 9.4345 1.77497 9.43593 1.60739 9.53202C1.43971 9.62811 1.33457 9.80484 1.33016 9.99801C1.32939 10.0306 1.32939 10.0631 1.32939 10.0961C1.32939 12.7366 2.75053 15.1139 4.9233 16.4096C4.73663 16.391 4.55008 16.3639 4.36474 16.3285C4.17367 16.292 3.97719 16.359 3.84833 16.5047C3.71926 16.6503 3.67656 16.8533 3.73603 17.0387C4.54026 19.5496 6.61087 21.3964 9.11404 21.9595C7.03792 23.2599 4.66371 23.941 2.17135 23.941C1.6513 23.941 1.12827 23.9104 0.616384 23.8499C0.362095 23.8196 0.118949 23.9698 0.0323473 24.2116C-0.0542543 24.4535 0.0375324 24.7233 0.253871 24.8619C3.4557 26.9149 7.15795 28 10.9601 28C18.4348 28 23.1107 24.4753 25.717 21.5183C28.9671 17.8313 30.8311 12.9511 30.8311 8.12907C30.8311 7.92763 30.828 7.7242 30.8218 7.52143C32.104 6.55535 33.208 5.38618 34.1064 4.04236C34.2428 3.83827 34.2281 3.56854 34.07 3.38066C33.9121 3.19267 33.649 3.13189 33.4244 3.2314Z"
    />
  </svg>

Twitter.defaultProps = {
  width: 35,
  height: 28,
  fill: '#FFFFFF'
}

Twitter.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
}

export default Twitter
