import { takeLatest, takeEvery, put, call } from 'redux-saga/effects';

import * as services from './services';
import Creators, { ReportTypes as constants } from './reducer';
import ErrorsActions from '../../Redux/ErrorsRedux'

const actions = {
  getReportActions: {
    request: Creators.getReportRequest,
    success: Creators.getReportSuccess,
  },
};

const eventsOptions = {
  [constants.GET_REPORT_REQUEST]: {
    api: services.getReport,
    actions: actions.getReportActions,
  },
};

function* apiGenerator(action) {
  const provider = eventsOptions[action.type];
  try {
    const params = action.payload;
    const response = yield call(provider.api, params);

    if (response.ok && response.data) {
      yield put(provider.actions.success(response.data));
    } else {
      yield put(Creators.reportFetchingReset());
      // yield put(ErrorsActions.errorSave(response.data ))
    }
  } catch (errors) {
    yield put(Creators.reportFetchingReset());
    // yield put(ErrorsActions.errorSave({ errors }));
  }
}

export default function* apiSaga() {
  yield takeLatest(constants.GET_REPORT_REQUEST, apiGenerator);

}
