import React from 'react'
import PropTypes from 'prop-types'

export const Favorite = ({width, height, isActive}) =>
  <svg width={width}
       height={height}
       viewBox="0 0 15 14"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.7462 0.59924C9.65446 0.59924 8.62586 1.02367 7.85292 1.79184L7.53411 2.10867L7.20929 1.78586C6.43634 1.01769 5.40775 0.593262 4.31599 0.593262C3.22725 0.593262 2.20166 1.01471 1.42871 1.78287C0.655759 2.55103 0.231689 3.57027 0.231689 4.65526C0.231689 5.74026 0.658767 6.7595 1.43172 7.52766L7.16117 13.2216L7.1732 13.2336C7.27245 13.3322 7.40479 13.3801 7.53411 13.3801C7.66645 13.3801 7.79878 13.3292 7.89803 13.2306L13.6275 7.53663C14.4004 6.76846 14.8275 5.74923 14.8275 4.66423C14.8275 3.57924 14.4034 2.55701 13.6305 1.79184C12.8605 1.02068 11.835 0.59924 10.7462 0.59924ZM12.8996 6.81031L7.53712 12.1426L2.16256 6.80134C1.5851 6.22746 1.2663 5.46527 1.26329 4.65228C1.26329 3.84227 1.57909 3.08008 2.15655 2.5062C2.734 1.93531 3.50094 1.61848 4.31299 1.61848C5.13105 1.61848 5.89798 1.93531 6.47845 2.51218L7.16719 3.19665C7.35967 3.38794 7.70254 3.38794 7.89803 3.19665L8.58376 2.51517C9.16122 1.94128 9.93116 1.62445 10.7462 1.62445C11.5613 1.62445 12.3282 1.94128 12.9057 2.51517C13.4831 3.08905 13.8019 3.85123 13.7989 4.66423C13.7989 5.47424 13.4801 6.23643 12.8996 6.81031Z"
      fill={isActive ? '#E10252' : "#34315A"}/>
  </svg>

Favorite.defaultProps = {
  width: 15,
  height: 14,
}

Favorite.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  isActive: PropTypes.bool,
}

export default Favorite
