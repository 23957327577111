import React, {useState } from 'react'
import { Row, Button, Typography, Col } from 'antd'
import Icon from '../../../Icon'

import Images from '../../../Images'
import BannerModal from './BannerModal'
import './banner.less'

const { Title } = Typography;

export const Banner = ({...props}) => {

  const [openModal, setOpenModal] = useState(false)

  return (
    <div>
      <Row gutter={[0, 0]} className='banner-wrapper' >
        <Col span={6} className='fst-col-wrapper'>
            <img src={Images.banner} alt='' width={120} />
        </Col>
        <Col span={18} className='second-col-wrapper'>
          <Row align={'middle'}>
            <Col lg={24} xl={18}>
              <Title level={2} style={{fontWeight: 500}}>
                Discover the Potential of Machine Learning Algorithms to Solve Your Business Challenges.
              </Title>
              <Title level={2} className='banner-gradient-text'>
                Fill out the Form and Get Started.
              </Title>
            </Col>
            <Col lg={12} xl={6}>
              <Button
                className='dark-blue-button'
                style={{width: '100%', maxWidth: 300, minWidth: 226}}
                onClick={()=> setOpenModal(true)}
              >
                <Row align={'middle'} justify={'space-between'}>
                  Get free consultation&nbsp;&nbsp;
                  <Icon type="getintouch" role="icon" />
                </Row>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <BannerModal isOpen={openModal} setOpenModal={setOpenModal} />
    </div>
  )
}

export default Banner

