import React from 'react';
import { NavLink } from 'react-router-dom';
import cls from 'classname';
import Icon from '../../Icon';

export const NavLinkWithIcon = ({
  url,
  iconType,
  text,
  active,
  theme,
  isOpened,
  isSoon,
  markText,
  ...props
}) => (
  <NavLink
    to={url}
    onClick={(e) => (isSoon ? e.preventDefault() : e)}
    className={cls('sidebar-link', { disabled: isSoon })}
    activeClassName="selected"
  >
    <Icon
      className="sidebar-link_icon"
      type={iconType}
      role="icon"
      fill={!active ? '#FFFFFF' : '#D0D5DD'}
      theme={theme}
      {...props}
    />
    <span
      className={cls('sidebar-link_text', {
        'is-open': !isOpened,
        'is-closed': isOpened,
      })}
    >
      {text}
      {!!markText ? (
        <span className="sidebar-link_mark">{markText}</span>
      ) : null}
    </span>
  </NavLink>
);

export const NavLinkOnlyIcon = ({
  url,
  iconType,
  active,
  theme,
  isOpened,
  ...props
}) => (
  <NavLink to={url} className="sidebar-link" activeClassName="selected">
    <div className="sidebar-link_wrap">
      <Icon
        className="sidebar-link_icon"
        type={iconType}
        role="icon"
        fill={!active ? 'red' : '#D0D5DD'}
        theme={theme}
        {...props}
      />
    </div>
  </NavLink>
);

export const navIconsArr = {
  desktop: [
    {
      url: '/dashboard',
      iconDesk: 'home',
      // iconDesk: 'dashboard',
      text: 'dashboard',
    },
    {
      url: '/report',
      iconDesk: 'dashboard',
      text: 'report',
    },
  ],
  mobile: [
    {
      url: '/dashboard',
      iconDesk: 'home',
      text: 'dashboard',
    },
    {
      url: '/report',
      iconDesk: 'report',
      text: 'report',
    },
  ],
};

export const navIconsAdmin = {
  desktop: [
    {
      url: '/admin/customers',
      iconDesk: 'home',
      text: 'Customers',
    }
  ],
  mobile: [
    {
      url: '/admin/customers',
      iconDesk: 'home',
      text: 'Customers',
    }
  ]
};
